// Dependencies
import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import classNames from "classnames";

import ProcedureTableResults from "./ProcedureTableResults";
import IllustrationLineSeparator from "../../../components/IllustrationLineSeparator";
import UploadFileButton from "../../../components/UploadFileButton";
import Select from "../../../components/Select";
import File from "../../../components/File";
import ModalInfoAction from "../../../components/admin-modals/ModalInfoAction";
import ProcedureModalUploadFile from "../components/ProcedureModalUploadFile";
import ProcedureTableDocuments from "../components/ProcedureTableDocuments";


const DOCUMENT_OPTIONS = [
  { value: 0, text: "Informe Consolidado AC" },
  { value: 1, text: "Informe de Feedback AC" },
  { value: 2, text: "Informe de Supervisor AC" },
  { value: 3, text: "Reporte entrevista de feedback" },
  { value: 4, text: "Documento PDM plan de mejora" },
  { value: 5, text: "Informe de contraste a los 3 meses" },
  { value: 6, text: "Informe de contraste a los 6 meses" },
];
const ProcedureUserAdditionalDocuments = (props) => {
  console.log("additional", props);

  const [modalInfo, setModalInfo] = useState({});
  const [showModalUpload, setShowModalUpload] = useState(false);

  const sendData = (data) => {
    setShowModalUpload(false);
    props.uploadDocument(data);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>
        {/* <h3>Upload new additional document</h3> */}
        {/* <div style={{ display: "flex", width: 601 }}>
          <div>
            {editData["file"] === undefined && (
              <UploadFileButton
                label={"Organization logo"}
                imageName={"nameLogo()"}
                image={false}
                textButton={"Upload file"}
                getFile={(file) => setEditData({ ...editData, ["file"]: file })}
                classNameUploadButton={"c-upload-button--max-width"}
              />
            )}
            {editData["file"] && (
              <React.Fragment>
                <File width="20px" height="23px" color={"#76b0eb"} />
                <span className="c-upload-file-input__add-file-hint">
                  {editData["file"]["original_filename"]}
                </span>
              </React.Fragment>
            )}
          </div>
          <div className="c-sidebar__nav-item-option-content">
            <p className="o-font-p--small">Select file type</p>
            <Select
              className="c-select--white-bg c-select--border"
              errorLabel=""
              withoutInput={true}
              tick={false}
              valueSelected={editData["category"]}
              options={DOCUMENT_OPTIONS}
              optionClick={(text, value) => {
                setEditData({
                  ...editData,
                  ["category"]: value,
                  ["category_title"]: text,
                });
              }}
              wrapperClassName={"c-select__wrapper--height-zero"}
            />
          </div>
          <button
            type="button"
            className="c-button c-button--cancel c-modal__button"
            disabled={editData["file"] === undefined}
            onClick={() => setEditData({})}
          >
            <span className="c-button__text">Cancel</span>
          </button>
          <button
            type="button"
            className="c-button c-modal__button"
            disabled={editData["file"] === undefined}
            onClick={() => sendData()}
          >
            <span className="c-button__text">Confirm</span>
          </button>
        </div> */}
        <div>
          <div className="o-margin--top-size-xl o-display--flex o-display--flex-v-center o-margin--bottom-size-xl">
            <h4 className="o-margin--right-size-l">Documenti aggiuntivi</h4>
            <button
              type="button"
              className={`c-button c-button--margin-zero`}
              onClick={() => setShowModalUpload(true)}
            >
              <span className="c-button__text">Upload file</span>
            </button>
          </div>

          <ProcedureTableDocuments deleteDocument={(id) => setModalInfo({ id: id })} data={props.data}/>
          {/* {props.data &&
            props.data.data &&
            props.data.data.map((document) => {
              return (
                <div>
                  <span>{document.original_name}</span>
                  <span> - {document.category_name} - </span>
                  <a href={document.url_document} target="_blank">
                    Ver
                  </a>
                  <button onClick={() => setModalInfo({ id: document.id })}>
                    Eliminar
                  </button>
                </div>
              );
            })} */}
        </div>
      </div>
      {showModalUpload && (
        <ProcedureModalUploadFile
          saveData={(data) => sendData(data)}
          showModal={showModalUpload}
          closeModal={() => setShowModalUpload(false)}
          documentOptions={DOCUMENT_OPTIONS}
        />
      )}
      {Object.keys(modalInfo).length > 0 && (
        <ModalInfoAction
          showModalInfoAction={true}
          noButtonCancel={false}
          closeModalInfoAction={() => setModalInfo({})}
          text={"Are you sure you want to delete the document?"}
          action={() => props.deleteDocument(modalInfo.id)}
        />
      )}
    </motion.div>
  );
};

export default ProcedureUserAdditionalDocuments;
