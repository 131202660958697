import React, { useEffect, useState, useRef } from "react";

// Components
import Modal from "../Modal";
import Input from "../Input";

import Multiselect from "../Multiselect";
import UploadFileButton from "../UploadFileButton";
import Checkbox from "../Checkbox";
import Close from "../Close";
import Datepicker from '../Datepicker'
import Switch from "react-switch";

import {DICTIONARY} from "../../lib/dictionaries";

const ModalCreate = (props) => {
  const [editData, setEditData] = useState({});
  const [biodataOptions, setBiodataOptions] = useState([]);
  const [options, setOptions] = useState(undefined);
  const [switchProcedureOpen, setSwitchProcedureOpen] = useState(false);
  const [assessmentsClonable, setAssessmentsClonable] = useState([]);
  const [biodataRequired, setBiodataRequired] = useState([]);
  const [languageElementsSelected, setLanguageElementsSelected] = useState([]);
  const refItemAssesments = useRef([]);
  const refItemBiodataOptions = useRef([]);
  const refItemLanguages = useRef([]);

  useEffect(() => {
    if (props.type === "edit") {
      setEditData({
        name: props.defaultData && (props.defaultData.name || props.defaultData.title),
        description: props.defaultData && props.defaultData.description,
      });
    }
    else if (props.type == "edit_procedure"){
      setEditData({
        biodataOptions: props.biodataOptions,
        name: props.defaultData && (props.defaultData.name || props.defaultData.title),
        description: props.defaultData && props.defaultData.description,
        start_date: props.defaultData && props.defaultData.start_date !== "" ? new Date(props.defaultData.start_date) :  new Date(),
        end_date: props.defaultData && props.defaultData.end_date !== "" ? new Date(props.defaultData.end_date) : new Date(),
      });
      setBiodataOptions(props.biodataOptions);
      setSwitchProcedureOpen(props.defaultData.public)

    } else if (props.type === "editOrganization") {
      let getIdAsessment = props.defaultData.assessments.map((item) => item.id);
      setEditData(props.defaultData);
      setBiodataOptions(props.defaultData.biodataOptions);
      setOptions(getIdAsessment);
      setAssessmentsClonable(getIdAsessment);

      setTimeout(() => {

        refItemBiodataOptions.current.map((item) => {
          if (props.defaultData.biodataOptions.includes(item.id)) {
            item.click();
          }
        });

        refItemAssesments.current.map((item) => {
          if (getIdAsessment.includes(parseInt(item.id))) {
            item.click();
          }
        });
      }, 1000);
    }
  }, [props.defaultData]);

  const getEditValues = (e) => {
    setEditData({ ...editData, [e.currentTarget.name]: e.currentTarget.value });
  };

  const optionSelect = (option) => {
    let newOption;
    if (["createOrganization", "editOrganization", "createProcedure"].includes(props.type)) {
      if (props.assessments.length === 1) {
        newOption = [option];
      } else {
        newOption = option;
      }

      let newAssessmentsClonable = newOption.filter(i => assessmentsClonable.indexOf(i) !== -1);

      setAssessmentsClonable(newAssessmentsClonable)
      setOptions(newOption);
    } else {
      setEditData({ ...editData, ["assessment_id"]: option });
    }
  };

  const getOptionsSelect = () => {
    return (
      props.assessments &&
      props.assessments.map((item, index) => {
        return { value: item.id, text: item.text };
      })
    );
  };

  const checkOrganizationData = () => {
    return (
      options !== undefined &&
      editData.name !== undefined &&
      editData.name !== ""
    );
  };

  const checkFolderData = () => {
    return editData.name !== undefined && editData.name !== "";
  };

  const showSelect = () => {
    if (
      ["createOrganization", "createFolder", "editOrganization", "copy_folder", "createProcedure"].includes(
        props.type
      )
    ) {
      return true;
    } else if (
      (props.type === "edit" || props.type == "edit_procedure") &&
      props.defaultData &&
      props.defaultData.survey === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getClonable = (id) => {
    let allIds = assessmentsClonable;

    if (allIds.length > 0) {
      if (allIds.find((item) => item === id) === undefined) {
        allIds = [...allIds, id];
      } else {
        allIds = allIds.filter((item) => item !== id);
      }
    } else {
      allIds = [...allIds, id];
    }

    setAssessmentsClonable(allIds);
  };

  const biodataRequiredSelected = (id) => {
    let allIds = biodataRequired;

    if (allIds.length > 0) {
      if (allIds.find((item) => item === id) === undefined) {
        allIds = [...allIds, id];
      } else {
        allIds = allIds.filter((item) => item !== id);
      }
    } else {
      allIds = [...allIds, id];
    }
    setBiodataRequired(allIds);    
  }

  const removeAssessment = (id) => {
    let assessments = options.filter((item) => item !== id);
    let _assessmentsClonable = assessmentsClonable.filter(
      (item) => item !== id
    );

    refItemAssesments.current.map((item) => {
      if (item.id === id + "") {
        item.click();
      }
    });

    setOptions(assessments);
    setAssessmentsClonable(_assessmentsClonable);
  };

  const removeBiodataOption = (id) => {
    let biodataFilter = biodataOptions.filter((item) => item !== id);

    refItemBiodataOptions.current.map((item) => {
      if (item.id === id + "") {
        item.click();
      }
    });

    setBiodataOptions(biodataFilter);
  };

  const removeLanguage = (value) => {
    let languageFilter = languageElementsSelected.filter((item) => item !== value);
    refItemLanguages.current.map((item) => item.id === value + "" && item.click());
    setLanguageElementsSelected(languageFilter)
  };

  const sendData = () => {
    let getAssessments = options.map((item) => {
      return {
        id: item,
        clonable: assessmentsClonable.includes(item) ? true : false,
      };
    });

    delete editData["biodataOptions"];

    setEditData({
      ...editData,
      ["assessments"]: getAssessments,
      ["biodata_options"]: biodataOptions,
      ["biodata_required"]: ["createProcedure"].includes(props.type) ? biodataRequired : biodataOptions,
      ["public"]: switchProcedureOpen,
      ["languages_selected"]: languageElementsSelected
    });

    props.editAction({
      ...editData,
      ["assessments"]: getAssessments,
      ["biodata_options"]: biodataOptions,
      ["biodata_required"]: ["createProcedure"].includes(props.type) ? biodataRequired : biodataOptions,
      ["public"]: switchProcedureOpen,
      ["languages_selected"]: languageElementsSelected
    });
  };

  const prepareDataFolder = () => {
    props.editAction({
      ...editData,
      ["biodata_options"]: biodataOptions,
      ["biodata_required"]: biodataRequired,
      ["languages_selected"]: languageElementsSelected
    });
  }

  const nameLogo = () => {
    if (props.defaultData && props.defaultData.hasOwnProperty("logo")) {
      let name = props.defaultData.logo.split("/");
      return name[name.length - 1];
    } else {
      return false;
    }
  };

  const showImage = () => {
    if("createOrganization"){
      if(Object.keys(editData).length > 0 && editData.hasOwnProperty("logo")){
        return true
      }else{
        return false
      }
    }else {
      return true
    }
  }

  const setLanguageSelected = (option) => {
    console.log(option)
  }

  return (
    <Modal isVisible={props.showModal} medium className='c-modal-create'>
      <div className="o-width--full-width">
        <form>
          <h2 className="c-modal-create__title">{props.title}</h2>
          { props.description && 
          <p className="c-modal-create__description">{props.description}</p>
          }
          <Input
            name="name"
            label="Nome"
            errorLabel="This field can not be blank"
            minCharacters={5}
            type="text"
            value={editData.name}
            onChange={(e) => getEditValues(e)}
          />
          {props.type !== "createOrganization" &&
              props.type !== "editOrganization" && (
                <Input
                  name="description"
                  label="Descrizione"
                  errorLabel="This field can not be blank"
                  minCharacters={5}
                  type="textarea"
                  value={editData.description}
                  onChange={(e) => getEditValues(e)}
                />
              )}
          {["createOrganization", "editOrganization"].includes(props.type) && (
            <div className='c-modal-create__colorpickers'>
              <Input
                className="c-input"
                name="primary_color"
                label="Colore primario"
                errorLabel="This field can not be blank"
                minCharacters={5}
                type="color"
                value={editData.primary_color}
                onChange={(e) => getEditValues(e)}
                wrapperClassName="c-modal-create__colorpicker"
              />
              <Input
                className="c-input"
                name="secondary_color"
                label="Colore secondario"
                errorLabel="This field can not be blank"
                minCharacters={5}
                type="color"
                value={editData.secondary_color}
                onChange={(e) => getEditValues(e)}
                wrapperClassName="c-modal-create__colorpicker"
              />
            </div>
          )}
          {["createProcedure", "edit_procedure"].includes(props.type) && (
            <React.Fragment>
              <div className='c-modal-create__datepickers'>
                <Datepicker
                  hasTime={true}
                  isRequired={true}
                  value={editData.start_date || new Date()}
                  label={"Data d'inizio"}
                  errorLabel={""}
                  onChange={(dateValue) => setEditData({ ...editData, ["start_date"]: dateValue })}
                  wrapperClassName="c-modal-create__datepicker"
                />
                <Datepicker
                  hasTime={true}
                  isRequired={true}
                  value={editData.end_date || new Date()}
                  label={"Data di fine"}
                  errorLabel={""}
                  onChange={(dateValue) => setEditData({ ...editData, ["end_date"]: dateValue })}
                  wrapperClassName="c-modal-create__datepicker"
                />
              </div>
              <div className="c-switch__container" key={"switch_open_close"}>
                <label className="c-switch__label">Public</label>
                <Switch
                  onChange={(checked) => {
                    setSwitchProcedureOpen(checked)
                  }}
                  disabled={props.type === "edit_procedure"}
                  checked={switchProcedureOpen}
                  className={`c-switch c-switch-`}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#3C5896"
                  offColor="#D9D9D9"
                  onHandleColor="#A9C44B"
                  offHandleColor="#3C5896"
                  handleDiameter={18}
                />
              </div>
            </React.Fragment>
          )}        
        </form>
        <div className="o-display--flex o-display--flex-v-center">
          {["createOrganization", "editOrganization"].includes(props.type) && (
            <UploadFileButton
              label={"Logo"}
              imageName={nameLogo()}
              image={true}
              getFile={(file) => setEditData({ ...editData, ["logo"]: file })}
              classNameUploadButton={showImage() ? "c-upload-button--full-width c-upload-button--max-width" : "c-upload-button--full-width"}
            />
          )}
          {
            showImage() && 
              <div className="c-wrapper-image o-margin--left-size-l">
                <img
                  className="c-wrapper-image__image"
                  src={
                    typeof editData.logo === "object"
                      ? `data:${editData.logo.content_type};base64,${editData.logo.data}`
                      : editData.logo
                  }
                  alt="logo"
                />
              </div>
          }

        </div>
      </div>
      {["createProcedure", "createFolder"].includes(props.type) && (
        <Multiselect
          refItem={refItemBiodataOptions}
          label={"Selezionare biodata:"}
          multiselectClassName="c-multiselect--bottom-twenty-four"
          multiple={true}
          options={props.biodataOptions}
          getOption={(option) => setBiodataOptions(option)}
        />
      )}

      {["createProcedure", "createFolder"].includes(props.type) &&
          biodataOptions.length > 0 && (
            <div className="c-tag__wrapper">
              {props.biodataOptions.map((item, index) => {
                if (biodataOptions.includes(item.value)) {
                  return (
                    <div
                      className="c-tag"
                      onClick={() => removeBiodataOption(item.value)}
                    >
                      <p className="c-tag-text">{item.text}</p>
                      <div className="c-tag-icon">
                        <Close width={"14px"} height={"13px"} color="#fc5c65" rotation/>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
      {["createProcedure", "createFolder"].includes(props.type) &&
          biodataOptions &&
          biodataOptions.length > 0 && (
            <>
            <div className="c-box-checkbox">
              <p className="c-box-checkbox__label">
                From the selected biodata, choose which ones will be required:
              </p>
              <div className="c-box-checkbox__wrapper">
                {biodataOptions &&
                    props.biodataOptions.map((item, index) => {
                      if (biodataOptions.length && biodataOptions.includes(item.value)) {
                        return (
                          <div className="c-box-checkbox__item">
                            <Checkbox
                              key={"check-email-title"}
                              text={item.text}
                              textClassName="c-checkbox__text"
                              onClick={() => biodataRequiredSelected(item.value)}
                              checked={
                                biodataRequired.includes(item.value)
                                  ? true
                                  : false
                              }
                            />
                            {biodataOptions.length > 1 && (
                              <div
                                className="c-box-checkbox__item-icon"
                                onClick={() => removeBiodataOption(item.value)}
                              >
                                <Close
                                  color="#fc5c65"
                                  width={"20px"}
                                  height={"19px"}
                                  rotation
                                />
                              </div>
                            )}
                          </div>
                        );
                      }
                    })}
              </div>
            </div>
              <div className='c-modal-create__separator'></div>
            </>
          )}


      {biodataOptions.includes("language") && (
        <Multiselect
          refItem={refItemLanguages}
          label={"Select available languages:"}
          multiselectClassName="c-multiselect--bottom-twenty-four"
          multiple={true}
          options={DICTIONARY["profile"]["languages"].map((value) => { return { value: value[0], text: value[1] }})}
          getOption={(option) => setLanguageElementsSelected(option)}
        />
      )}
      {["createProcedure", "createFolder"].includes(props.type) &&
          biodataOptions.includes("language") && languageElementsSelected.length > 0 && (
            <>
              <div className="c-tag__wrapper">
                {languageElementsSelected.map((item, index) => {
                  return (
                    <div
                      className="c-tag"
                      onClick={() => removeLanguage(item)}
                    >
                      <p className="c-tag-text">{item}</p>
                      <div className="c-tag-icon">
                        <Close width={"14px"} height={"13px"} color="#fc5c65" rotation/>
                      </div>
                    </div>
                  );
                })}

              </div>
              <div className='c-modal-create__separator'></div>
            </>
          )}
      {["edit_procedure", "edit"].includes(props.type) && props.defaultData.biodata && props.defaultData.biodata.length > 0 &&
        <>
          <div className="c-box-checkbox">
            <p className="c-box-checkbox__label">
              Biodata asked in the process (checked means required):
            </p>
            <div className="c-box-checkbox__wrapper">
              {props.defaultData.biodata && props.defaultData.biodata.map((bio) => {
                return (
                  <div className="c-box-checkbox__item">
                    <Checkbox
                      key={"check-email-title"}
                      text={bio.title}
                      textClassName="c-checkbox__text"
                      onClick={() => ""}
                      disabled={true}
                      checked={bio.required}
                    />
                  </div>
                )
              })
              }
            </div>
          </div>
          <div className='c-modal-create__separator'></div>
        </>
      }        


      {showSelect() && (
        <Multiselect
          refItem={refItemAssesments}
          label={"Selezionare prova:"}
          multiselectClassName="c-multiselect--bottom-twenty-four"
          multiple={
            ["createOrganization", "editOrganization", "createProcedure"].includes(props.type) &&
              props.assessments &&
              props.assessments.length > 1
                ? true
                : false
          }
          options={getOptionsSelect()}
          getOption={(option) => optionSelect(option)}
        />
      )}

        {["createProcedure", "createFolder"].includes(props.type) &&
            options &&
            options.length > 0 && (
              <div className="c-box-checkbox">
                <p className="c-box-checkbox__label">
                  From the selected assessment:
                </p>
                <div className="c-box-checkbox__wrapper">
                  {options &&
                      getOptionsSelect().map((item, index) => {
                        if (options.length && options.includes(item.value)) {
                          return (
                            <div className="c-box-checkbox__item">
                              <span>{item.text}</span>
                              {options.length > 0 && (
                                <div
                                  className="c-box-checkbox__item-icon"
                                  onClick={() => removeAssessment(item.value)}
                                >
                                  <Close
                                    color="#fc5c65"
                                    width={"20px"}
                                    height={"19px"}
                                    rotation
                                  />
                                </div>
                              )}
                            </div>
                          );
                        }
                      })}
                </div>
              </div>
            )}

      {["createOrganization", "editOrganization"].includes(props.type) &&
          options &&
          options.length > 0 && (
            <div className="c-box-checkbox">
              <p className="c-box-checkbox__label">
                From the selected assessment, choose which ones will be cloned:
              </p>
              <div className="c-box-checkbox__wrapper">
                {options &&
                    getOptionsSelect().map((item, index) => {
                      if (options.length && options.includes(item.value)) {
                        return (
                          <div className="c-box-checkbox__item">
                            <Checkbox
                              key={"check-email-title"}
                              text={item.text}
                              textClassName="c-checkbox__text"
                              onClick={() => getClonable(item.value)}
                              checked={
                                assessmentsClonable.includes(item.value)
                                  ? true
                                  : false
                              }
                            />
                            {options.length > 1 && (
                              <div
                                className="c-box-checkbox__item-icon"
                                onClick={() => removeAssessment(item.value)}
                              >
                                <Close
                                  color="#fc5c65"
                                  width={"20px"}
                                  height={"19px"}
                                  rotation
                                />
                              </div>
                            )}
                          </div>
                        );
                      }
                    })}
              </div>
            </div>
          )}


      <div className="c-button__wrapper">
        <button
          type="button"
          className="c-button c-button--cancel c-modal__button"
          onClick={() => props.closeModal()}
        >
          <span className="c-button__text">Annullare</span>
        </button>
        <button
          type="button"
          className="c-button c-modal__button"
          disabled={
            props.type === "createOrganization"
              ? !checkOrganizationData()
              : !checkFolderData()
          }
          onClick={() =>
            ["createOrganization", "editOrganization", "createProcedure"].includes(props.type)
              ? sendData()
              : props.type === "createFolder" ? prepareDataFolder() : props.editAction(editData)
          }
        >
          <span className="c-button__text">Confermare</span>
        </button>
      </div>
    </Modal>
  );
};

export default ModalCreate;
