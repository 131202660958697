import React, { Component } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";

// Actions
import { fetchSurveys } from "./actions";
import {setUserLanguage} from '../users/actions'

// Components
import SurveyItem from "./components/SurveyItem";
import IllustrationLineSeparator from "../../components/IllustrationLineSeparator";

//localize

import internalization from "../../config/localize"

class SurveyIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: [],
      loaded: false,
      language: "es"
    };
  }

  componentDidMount() {
    
    $(window).scrollTop(0);
    let is_admin = localStorage.getItem('admin')

    if(is_admin == "true"){
      this.props.history.push("/admin")
    }else{
      this.props.fetchSurveys((data) => this._success(data));  
    }
    
  }

  _success(data){
    let root = document.documentElement;    
    root.style.setProperty("--primary-color", data.colors.primary_color);
    root.style.setProperty("--secondary-color", data.colors.secondary_color);
    
    internalization.setLanguage("it");
    this.props.setUserLanguage("it")
    this.setState({ surveys: data.surveys.data, loaded: true, language: "it" })
  }

  surveyClickStartButton(item){
    
    if(item.attributes.external_service === "criteria"){
       window.open(item.attributes.survey_settings.url, '_blank').focus();
    }
    else {
      this.props.history.push(`/survey/${item.attributes.token}`)  
    }
  }

  _renderListing(surveys) {
    return surveys.map((item, index) => {
      return (
        <div className="c-test" key={index}>
          <SurveyItem
            title={item.attributes.title}
            description={this.props.special_organization ? this._getSpecialOrganizationDescription(item.attributes.description) : item.attributes.description}
            image_url={item.attributes.image_url}
            ellipsis={false}
            closed={item.attributes.progress === 100 || item.attributes["is_ended?"]}
            ended={item.attributes["is_ended?"]}
            internalization={internalization}
            report_url={item.attributes.report_url}
            progress={item.attributes.progress}
            external_service={item.attributes.external_service}
            survey_settings={item.attributes.survey_settings}
            handleClick={() => { this.surveyClickStartButton(item) }}
          />
          {
            (index + 1) < (surveys.length) &&
              <IllustrationLineSeparator className="c-test__separator" />
          }
        </div>
      );
    });
  };

  _getSpecialOrganizationTitle = () => {
    if(this.state.surveys.length > 0){
      let survey = this.state.surveys[0];
      if(survey.attributes.progress === 100){
        if(this.state.language == "en"){
          return "Many thanks for finishing the survey."
        }else{
          return "Muchas gracias por finalizar el cuestionario"   
        }
        
      }
    }
    if(this.state.language == "en"){
      return 'Welcome at Mahou San Miguel survey.' 
    }else{
      return 'Bienvenid@ a la evaluación de competencias de Mahou San Miguel.' 
    }
  }

  _getSpecialOrganizationDescription = (description) => {
    if(this.state.surveys.length > 0){
      let survey = this.state.surveys[0];
      if(survey.attributes.progress === 100){
        if(this.state.language == "en"){
          return "Many thanks for having spent a few minutes answering these questions! Thanks to you we are closer to having a better tool."
        }else{
          return "¡Muchas gracias por dedicar unos minutos a contestar nuestro test! Gracias a ti estamos más cerca de tener una mejor herramienta"  
        }
        
      }
    }
    return description
  }

  render() {
    return (
      <motion.section
        className="o-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >

        <div className="o-container">
          <h1 className="o-margin--bottom-size-xl o-margin--bottom-size-xxl@md-and-up">
            {this.state.surveys && this.state.surveys.length > 0 ? (this.props.special_organization ? this._getSpecialOrganizationTitle() : internalization.survey.title) : internalization.survey.loading}
          </h1>
          {this._renderListing(this.state.surveys)}
          {
            this.state.surveys.length == 0 && this.state.loaded &&
              <h3>No tienes pruebas todavía</h3>
          } 
        </div>
      </motion.section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.client,
    special_organization: state.survey.special_organization
  }
};

const mapDispatchToProps = {
  fetchSurveys,
  setUserLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyIndex);
