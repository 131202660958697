import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

// Components
import Aside from "../../components/Aside";
import Modal from "../../components/Modal";
import FolderInfo from "../../components/FolderInfo";
import ModalInvitationFolder from "../../components/admin-modals/ModalInvitationFolder";
import ModalInfoAction from "../../components/admin-modals/ModalInfoAction";
import ModalCreate from "../../components/admin-modals/ModalCreate";
import ModalAddUser from "../../components/admin-modals/ModalAddUser";
import TableTools from "../../components/TableTools";
import Spinner from "../../components/Spinner";
import IllustrationLineSeparator from "../../components/IllustrationLineSeparator";
import IllustrationStar from "../../components/IllustrationStar";
import ModalRanking from "../../components/admin-modals/ModalRanking";
import Button from "../../components/Button";
import ModalSelectOrganization from "../../components/admin-modals/ModalSelectOrganization";

import Eye from "../../components/Eye";

import Roles from "../../config/roles";

// Actions
import {
  fetchOrganization,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchOrganizations,
  fetchFolder,
  fetchRankingProfileFolder,
  fetchUpdateRankingProfileFolder,
  fetchDestroyRankingProfileFolder,
  fetchCreateRankingProfileFolder,
  fetchRankingProfileFolderId,
  fetchRankedUsers  
} from "../admin/actions";

import { fetchLogout } from "../login/actions";

// Selectors
import { getOrganization, getOrganizations } from "../organizations/selector";

const RankingProfileIndex = (props) => {
  const [modals, setModals] = useState({});
  const [loading, setLoading] = useState(true);
  const [rankedUsers, setRankedUsers] = useState(null);
  const [ranking, setRanking] = useState({});

  useEffect(() => {
    props.organizations.length === 0 && props.fetchOrganizations();
    props.organization === undefined &&
      props.fetchOrganization(props.match.params.organizationId, 1, 10);
    // props.folder === undefined && props.fetchFolder(
    //   props.match.params.organizationId,
    //   props.match.params.folderId,
    //   () => {
    //     _fetch()
    //     setModals({});
    //   }
    // ); 
    _fetch()
    
  }, []);

  const _fetch = (selected_profile = "") => {
    let id = selected_profile === "" ? props.match.params.id : selected_profile
    props.fetchRankedUsers(props.match.params.organizationId, props.match.params.folderId, id, 
      (responseJSON) => _success(responseJSON),
      () => _error(),
    ); 
    props.fetchRankingProfileFolderId(props.match.params.organizationId, props.match.params.folderId, id, 
      (responseJSON) => setRanking(responseJSON),
      () => _error(),
    );   
  }

  const _success = (responseJSON) => {
    setLoading(false); 
    setRankedUsers(responseJSON.data)
    setModals({})
  }

  const actionModals = (type) => {
    let modal = {};
    if (type === "addAdmin") {
      modal.type = type;
      modal.text = "Aggiungi amministratori";
      modal.action = (data) => addAdmin(data);
    }
    else if(type == "show_ranking"){
      modal.type = type;
      modal.title = rankedUsers ? rankedUsers.ranked_name : "";
      modal.action = () => setModals("");
    } else {
      modal.type = type;
      modal.action = (profiled_selected) => {
        props.history.push({
          pathname: `/admin/organizations/${props.match.params.organizationId}/surveys/${props.match.params.folderId}/ranking_profiles/${profiled_selected}`,
          state: {
            selectedProfileId: profiled_selected
          },
        });
        _fetch(profiled_selected)
      };
    }

    setModals(modal);
  };

  const changeOrganization = (id) => {
    props.history.push(`/admin/organizations/${id}`);
  };

  const getAssessments = () => {
    props.fetchOrganizationGetAssessments(
      props.match.params.organizationId,
      () =>
        setModals({
          type: "createOrganization",
          action: (data) => submitCreateOrganization(data),
        })
    );
  };

  const submitCreateOrganization = (data) => {
    props.fetchOrganizationCreate(
      { organization: data, from_organization: props.match.params.organizationId },
      () =>
        setModals({
          type: "succes",
          text: "Organization has been created successfully!",
          action: () => setModals({}),
        }),
      props.fetchOrganizations()
    );
  };

   // Logout
   const closeSession = () => {
    removeAdminScopeClass();
    props.fetchLogout() && props.history.push("/signin");
  };

  // Remove s-admin Class
  const removeAdminScopeClass = () => {
    let sAdmin = document.querySelector(".s-admin");
    sAdmin && sAdmin.classList.remove("s-admin");
  };

  const createRanking = (data, success) => {
    props.fetchCreateRankingProfileFolder(props.match.params.organizationId, props.match.params.folderId, {...data, folder_id: props.match.params.folderId }, () => success(), () => {})
  }

  const destroyRanking = (id, success) => {
    props.fetchDestroyRankingProfileFolder(props.match.params.organizationId, props.match.params.folderId, id, () => success(), () => {})
  }

  const getCompetenceTitle = (key) => {
    let compt = rankedUsers.ranking_data.competences.filter(c => c.key == key)
    if(compt.length > 0){
      return compt[0].title
    }else{
      return key
    }
  }


  return (
    <motion.section
      className="o-wrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >

      {props.organizations &&
        props.organizations.length > 0 &&
        props.organization &&
        props.organization.data && (
          <Aside
            history={props.history}
            label={"Select organization:"}
            showSelect={true}
            organization={props.organization && props.organization}
            organizations={props.organizations}
            selectAction={(id) => changeOrganization(id)}
            createOrganization={() => getAssessments()}
            selectOrganization={() => setModals("selectOrganization")}
            showModalSession={() => setModals({
              type: "closeSession",
              text: "Are you sure you want to logout?",
              action: () => closeSession(),
            })
          }
          />
        )}

      <div className="o-container">
        <div className="o-margin--top-size-xl o-display--flex o-display--flex-v-center">
          <h4 className="o-margin--right-size-l">Ranked Lista candidati ({rankedUsers && rankedUsers.ranked_name})</h4>
          <Button
            name="start"
            className="c-button c-button--margin-zero o-margin--right-size-l"
            type="button"
            text={"Show"}
            icon={<Eye />}
            onClick={() => actionModals("show_ranking")}
          />
          <button
            type="button"
            className={"c-button c-button--margin-zero"}
            onClick={() => {
              actionModals("ranking_report");
              document.body.style.overflow = 'hidden'
            }}
          >
            <div className="c-button__icon">
              <IllustrationStar className="c-rating-star__svg" borderColor="white" color="black" width={18} height={17}/>
            </div>
            <span className="c-button__text">Ranking</span>
          </button>
        </div>
        {rankedUsers && rankedUsers.user && rankedUsers.user.length > 0 ? (
          <TableTools
            data={rankedUsers}
            canManage={
              props.organization &&
              props.organization.user_role !== Roles.admin.READER
            }
            titleTableFilter="Ranked Lista candidati"
            type={"ranked_users"}
            filterStatus={""}
          />
        ) : (
          <>
            <IllustrationLineSeparator />
            <h2 className="o-margin--top-size-xxl o-margin--bottom-size-xxl o-text-align--center">
              No ranked users yet
            </h2>
            <IllustrationLineSeparator />
          </>
        )}
      </div>
      {
          modals === "selectOrganization" && (
            <ModalSelectOrganization
              history={props.history}
              organizations={props.organizations_select}
              closeModal={() => setModals("")}
            />
          )
        }
      {
        modals.type === "ranking_report" && (
         <ModalRanking
          data={modals}
          ranking_data={rankedUsers.ranking_data}
          showModalInvitationFolder={modals.type === "invite"}
          closeInvitationFolder={() => setModals("")}
          selectedProfile={(profiled_selected) => modals.action(profiled_selected)}
          createRanking = {(data, success) => createRanking(data, success)}
          destroyRanking = {(id, success) => destroyRanking(id, success)}      
          fetchRankingProfile={(success, error) => props.fetchRankingProfileFolder(props.match.params.organizationId, props.match.params.folderId, (repsonseJSON) => success(repsonseJSON), () => error())}
        /> 
      )}
      {
        modals.type === "show_ranking" && (
          <Modal isVisible={true} medium>
            <h2 style={{marginBottom: 20}}>Ranking: {modals.title}</h2>
            <div>
              {
                ranking && ranking.data && ranking.data.length > 0 && Object.keys(ranking.data[0].competences).map((key) => {
                  return (
                    <p style={{marginBottom: 5}}><b>{getCompetenceTitle(key)}</b>: {ranking.data[0].competences[key].scope} {ranking.data[0].competences[key].weight}%</p>
                  )
                })
              }
            </div>
            <div className="c-modal__buttons-wrapper">
              <button 
                type="button"
                className="c-button c-modal__button"
                onClick={() =>
                  {modals.action()}
                }
              >
                <span
                  className="c-button__text"
                >
                  Accept
                </span>
              </button>
            </div>
          </Modal>          
        )
      }
      {loading && <Spinner />}
    </motion.section>
  );
};

RankingProfileIndex.propTypes = {
  folder: PropTypes.object,
};

const mapStateToProps = (state, props) => {
  return {
    folder: state.admin.folder,    
    organization: getOrganization(state, props),
    organizations: getOrganizations(state, props),
    assessments: state.admin.assessments,
    admins: state.admin.admins,
    organizations_select: state.admin.organizations_select    
  };
};

const mapDispatchToProps = {
  fetchFolder,
  fetchOrganizations,
  fetchOrganization,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchRankingProfileFolder,
  fetchRankingProfileFolderId,
  fetchUpdateRankingProfileFolder,
  fetchDestroyRankingProfileFolder,
  fetchCreateRankingProfileFolder,
  fetchRankedUsers,
  fetchLogout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RankingProfileIndex);
