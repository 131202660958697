import React from "react";
import { motion } from "framer-motion";

// Components
import SurveyModule from "./SurveyModule";


const SurveyModuleContainer = (props) => {
  return (
    <React.Fragment>
      <motion.h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.1 }}
        className="o-margin--bottom-size-xl o-margin--bottom-size-xxl@md-and-up"
      >
        {props.internalization.survey.modules}
      </motion.h1>
      {
        props.description_modules && props.description_modules != "" &&
        <p style={{marginBottom: 30}} dangerouslySetInnerHTML={{__html: props.description_modules}}></p>
      }
      {
        Object.entries(props.modules).map((module, i) => {
          return (
            <SurveyModule
              key={i}
              module_key={module[0]}
              module={module[1]}
              history={props.history}
              internalization={props.internalization}
              last={Object.entries(props.modules).length - 1 === i && true}
            />
        );
      })}
    </React.Fragment>
  );
};

export default SurveyModuleContainer;
