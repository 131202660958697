import React from "react";
import { connect } from "react-redux";

// Components
import LinkAnchor from "./LinkAnchor";

import internalization from "../config/localize"

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let footerLinks = [
        { text: internalization.footer.technical_problems, href: "mailto: metrics@selexi.it", target: "_blank" },
        { text: internalization.footer.legal_advice, href: "/policy", target: "_blank" },
      ]
    return (
      <footer className="c-footer">
        <ul className="o-list-bare c-footer__list">
          {footerLinks.map((link, index) => {
            return (
              <li className="o-list-bare__item c-footer__link" key={index+"_"+this.props.user_language}>
                <LinkAnchor href={link.href} text={link.text} target={link.target}/>
              </li>
            );
          })}
        </ul>
      </footer>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    user_language: state.user.user_language
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
