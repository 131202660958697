import PropTypes from "prop-types";
import React from "react";
import {motion} from "framer-motion";

// Components
import Logo from "./Logo";

const Spinner = (props) => {

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="c-spinner"
    >
      <h2 className="o-margin--bottom-size-xxl">Caricamento...</h2>
      {/* <motion.div 
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration: 3.5, ease: "linear" }}
      >
        <Logo className="c-spinner__logo"/>
      </motion.div> */}
    </motion.div>
  )

}

export default Spinner;
