import React, { useEffect, useState } from "react";

// Components
import Modal from "../Modal";
import Input from "../Input";
import Multiselect from "../Multiselect";

const ModalAddUser = (props) => {
  const [editData, setEditData] = useState({});
  const [options, setOptions] = useState(undefined);
  const [error, setError] = useState(true);

  const getEditValues = (e) => {
    if (e.currentTarget.name === "email") {
      if (validateEmail(e.currentTarget.value)) {
        setError(false);
      } else {
        setError(true);
      }
    }

    setEditData({ ...editData, [e.currentTarget.name]: e.currentTarget.value });
  };

  const optionSelect = (option) => {
    setOptions(option);
    setEditData({ ...editData, ["role"]: option });
  };

  const validateEmail = (email) => {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const checkData = () => {
    return !error && editData.email && editData.role !== undefined;
  };

  return (
    <Modal isVisible={props.showModal} medium>
      <div className="o-width--full-width">
        <form>
          <h2 className="o-margin--bottom-size-l">{props.title}</h2>
          <Input
            name="email"
            label="Email"
            errorLabel="You must enter a correct email"
            minCharacters={5}
            showError={!validateEmail(editData.email) && editData.email}
            type="text"
            value={editData.email}
            onChange={(e) => getEditValues(e)}
          />

          <Input
            name="name"
            label="Nome"
            errorLabel="This field can not be blank"
            minCharacters={5}
            type="text"
            value={editData.name}
            onChange={(e) => getEditValues(e)}
          />
          <Input
            name="surname"
            label="Cognome"
            errorLabel="This field can not be blank"
            minCharacters={5}
            type="text"
            value={editData.surname}
            onChange={(e) => getEditValues(e)}
          />
        </form>
      </div>
      <Multiselect
        label={"Selezione ruolo:"}
        multiselectClassName="c-multiselect--bottom-twenty-four"
        multiple={false}
        options={props.options}
        getOption={(option) => optionSelect(option)}
      />

      <div className="c-modal__buttons-wrapper">
        <button
          type="button"
          className="c-button c-button--cancel c-modal__button"
          onClick={() => props.closeModal()}
        >
          <span className="c-button__text">Cancel</span>
        </button>
        <button
          type="button"
          className="c-button c-modal__button"
          disabled={!checkData()}
          onClick={() => props.action(editData)}
        >
          <span className="c-button__text">Confirm</span>
        </button>
      </div>
    </Modal>
  );
};

export default ModalAddUser;
