import React, { useState, useRef, useEffect } from 'react';

import PropTypes from "prop-types";
import { Editor } from '@tinymce/tinymce-react';

// Components
import RatingStar from "../../../../components/RatingStar";

const SurveyWysiwygItem = (props) => {
  const [initialValue, setInitialValue] = useState(undefined);
  const editorRef = useRef(null);

  useEffect(() => {
    // a real application might do a fetch request here to get the content
    setTimeout(() => setInitialValue(getInitialValue()), 500);
  }, []);

  const getContent = () => {
    if (editorRef.current) {
      let prepare_object = props.questionData.items;
      
      let preapre_object_item = prepare_object[0]["items"][0]
      preapre_object_item["text"] = editorRef.current.getContent()
      return props.handleClick({ [0]: preapre_object_item});
    }
  };

  const getInitialValue = () => {
    if(Object.keys(props.selectedItems).length > 0 && props.selectedItems[0] !== undefined){
      return props.selectedItems[0].text
    }
    else{
      return ""
    }        
  }

  return (
    <React.Fragment>
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        onChange={() => getContent()}
        apiKey="ettm7s8a008s2q3t32y60u1dsb0mmg9hh6g04d9q8r26w9w0"
        initialValue={initialValue}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat ",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />

    </React.Fragment>
  );
}

SurveyWysiwygItem.propTypes = {
  questionData: PropTypes.object,
  selectedItems: PropTypes.object,
  renderSelect: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

export default SurveyWysiwygItem;
