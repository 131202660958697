import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { motion } from "framer-motion";

import {DICTIONARY} from "../../lib/dictionaries";

// Actions
import { fetchShowProfile, fetchUpdateProfile, setUserLanguage } from "./actions";

// Components
import Select from "../../components/Select";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Datepicker from "../../components/Datepicker";
import Modal from "../../components/Modal";

import internalization from "../../config/localize"

class Profile extends Component {
  constructor(props) {
    super(props);
    this.paginationButtonFirst = React.createRef();
    this.paginationButtonSecond = React.createRef();
    this.state = {
      profile: {
        name: "",
        surname: "",
        lastname: "",
        language: "",
        gender: "",
        birthdate: "",
        country: "",
        department: "",
        training: "",
        job_position: "",
        personal_identifier: "",
        city: "",
        liability: "",
        sector: "",
        language_index: ""
      },
      showModal: false,
      modalData: {title: "", action: ""},      
      submitting: true,
      updated_language: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    try{
      if(props.profile && !(Object.keys(props.profile).join(',') === Object.values(state.profile).join(','))) {
        const profileStateValues = Object.values(state.profile).filter(v => v.length);
        const profilePropsValues = Object.values(props.profile).filter(v => v.length);

        state.profile = profileStateValues.filter(v => v.length).length ? state.profile : props.profile;
        state.submitting = (profileStateValues.length === 0) || (profileStateValues.join(',') === profilePropsValues.join(','))
      } else {
        state.submitting = false;
      }
    }catch(e){
      console.log(e)
    }
    

    return state
  }

  componentDidMount = () => {
    this.props.fetchShowProfile(this.props.userId, (responseJSON) => this._successGetProfile(responseJSON), () => this._error() );
  };

  _successGetProfile(responseJSON){
    console.log("_successGetProfile", responseJSON)
    internalization.setLanguage(responseJSON.user_language);
    this.props.setUserLanguage(responseJSON.user_language)
    this.setState({updated_language: true})
  }

  _handlePagination = (step, state) => {
    this.setState({ step }, () => {
      if (step === 1) {
        console.log("e", this.state.step);
        this.paginationButtonSecond.current.classList.remove("is-active");
        this.paginationButtonFirst.current.classList.add("is-active");
      } else {
        console.log("h", this.state.step);
        this.paginationButtonFirst.current.classList.remove("is-active");
        this.paginationButtonSecond.current.classList.add("is-active");
      }
    });
  };

  _setProfileState = (type, value) => {
    if(type == "language"){
      internalization.setLanguage(value)
      this.props.setUserLanguage(value)
    }
    this.setState((prevState) => ({
      ...prevState,
      profile: { ...prevState.profile, [type]: value },
    }));

  };

  _submit = (profileState) => {
    let data = { ...profileState };
    Object.keys(data).forEach(
      (key) => data[key].length == 0 && delete data[key]
    );

    if (Object.keys(data).length) {
      this.setState({ submitting: true });
      this.props.fetchUpdateProfile(
        this.props.userId,
        { profile: data },
        () => this._success(),
        () => this._error()
      );
    }
  };

  _success(){
    this.setState({showModal: true, modalData: {title: internalization.profile.success_title, action: () => this.setState({showModal: false})}})
  }

  _error(){
    this.setState({showModal: true, modalData: {title: internalization.errors.unexpected, action: () => this.setState({showModal: false})}})
  }

  render = () => {
    console.log(this.state)
    return (
      <motion.section
        className="o-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {
          this.props.profile && (
          <div className="o-container">
            <div className="u-width--1/2@lg">
              <h1 className="o-margin--bottom-size-l">{internalization.profile.title}</h1>
              <p className="o-margin--bottom-size-l">{internalization.profile.subtitle}</p>
            </div>
            <div className="o-display--flex@lg-and-up">
              <div className="o-col--1/2@lg o-padding--right@lg-only">
                <form>
                  <Input
                    label="Email"
                    errorLabel={internalization.form.empty}
                    isRequired={true}
                    minCharacters={2}
                    showHint={true}
                    hintLabel={internalization.form.email_not_changable}
                    type="text"
                    value={this.state.profile.email}
                    disabled={true}
                  />                
                  <Input
                    label={internalization.form.name}
                    errorLabel={internalization.form.empty}
                    isRequired={true}
                    minCharacters={2}
                    type="text"
                    value={this.state.profile.name}
                    onChange={(e) => this._setProfileState("name", e.currentTarget.value)}
                  />
                  <Input
                    label={internalization.form.surname}
                    errorLabel={internalization.form.empty}
                    isRequired={true}
                    minCharacters={2}
                    type="text"
                    value={this.state.profile.surname}
                    onChange={(e) => this._setProfileState("surname", e.currentTarget.value)}
                  />
                  <Input
                    label={internalization.form.lastname}
                    errorLabel={internalization.form.empty}
                    isRequired={true}
                    minCharacters={2}
                    type="text"
                    value={this.state.profile.lastname}
                    onChange={(e) => this._setProfileState("lastname", e.currentTarget.value)}
                  />
                  <Input
                    label={internalization.form.personal_identifier}
                    errorLabel={internalization.form.empty}
                    isRequired={true}
                    minCharacters={2}
                    type="text"
                    value={this.state.profile.personal_identifier}
                    onChange={(e) => this._setProfileState("personal_identifier", e.currentTarget.value)}
                  />
                  <Datepicker
                    label={internalization.form.birthdate}
                    errorLabel={internalization.form.empty}
                    isRequired={true}
                    initDateValue={this.state.profile.birthdate ? new Date(this.state.profile.birthdate) : null}
                    onChange={(dateValue) => this._setProfileState("birthdate", dateValue) }
                  />
                  <Select
                    label={internalization.form.gender}
                    tick={true}
                    withoutInput={true}
                    isRequired={true}
                    hasError={(value) => {}}
                    errorLabel={internalization.form.empty}
                    defaultValue={this.props.profile.gender}
                    valueSelected={this.state.profile.gender}
                    options={internalization.dictionaries.genders.map((value) => { return { value: value[0], text: value[1] }})}
                    optionClick={(text, value) => this._setProfileState("gender", value) }
                  />
                </form>
              </div>
              <div className="o-col--1/2@lg o-padding--left@lg-and-up">
                <form>
                  <Select
                    withoutInput={true}
                    tick={true}
                    label={internalization.form.city}
                    isRequired={true}
                    hasError={(value) => {}}
                    errorLabel={internalization.form.empty}
                    defaultValue={this.props.profile.city}
                    valueSelected={this.state.profile.city}
                    options={DICTIONARY["profile"]["cities"].map((value) => { return { value: value[0], text: value[1] }})}
                    optionClick={(text, value) => this._setProfileState("city", value) }
                  />
                  <Select
                    withoutInput={true}
                    tick={true}
                    label={internalization.form.country}
                    isRequired={true}
                    hasError={(value) => {}}
                    errorLabel={internalization.form.empty}
                    defaultValue={this.props.profile.country}
                    valueSelected={this.state.profile.country}
                    options={DICTIONARY["profile"]["countries"].map((value) => { return { value: value[0], text: value[1] }})}
                    optionClick={(text, value) => this._setProfileState("country", value) }
                  />
                  <Select
                    withoutInput={true}
                    tick={true}
                    label={internalization.form.language}
                    isRequired={true}
                    hasError={(value) => {}}
                    errorLabel={internalization.form.empty}
                    defaultValue={this.props.profile.language}
                    valueSelected={this.state.profile.language}
                    options={internalization.dictionaries.languages.map((value) => { return { value: value[0], text: value[1] }})}
                    optionClick={(text, value) => {this._setProfileState("language", value)} }
                  />
                  <Select
                    withoutInput={true}
                    tick={true}
                    label={internalization.form.training}
                    isRequired={true}
                    hasError={(value) => {}}
                    errorLabel={internalization.form.empty}
                    defaultValue={this.props.profile.training}
                    valueSelected={this.state.profile.training}
                    options={internalization.dictionaries.trainings.map((value) => { return { value: value[0], text: value[1] }})}
                    optionClick={(text, value) => this._setProfileState("training", value) }
                  />
                  <Select
                    withoutInput={true}
                    tick={true}
                    label={internalization.form.job_position}
                    isRequired={true}
                    hasError={(value) => {}}
                    errorLabel={internalization.form.empty}
                    defaultValue={this.props.profile.job_position}
                    valueSelected={this.state.profile.job_position}
                    options={internalization.dictionaries.job_positions.map((value) => { return { value: value[0], text: value[1] }})}
                    optionClick={(text, value) => this._setProfileState("job_position", value)}
                  />
                  <Input
                    label={internalization.form.department}
                    errorLabel={internalization.form.empty}
                    isRequired={false}
                    minCharacters={5}
                    type="text"
                    value={this.state.profile.department}
                    onChange={(e) => this._setProfileState("department", e.currentTarget.value)}
                  />
                  <Select
                    withoutInput={true}
                    tick={true}
                    label={internalization.form.liability}
                    isRequired={true}
                    hasError={(value) => {}}
                    errorLabel={internalization.form.empty}
                    defaultValue={this.props.profile.liability}
                    valueSelected={this.state.profile.liability}
                    options={internalization.dictionaries.liabilities.map((value) => { return { value: value[0], text: value[1] }})}
                    optionClick={(text, value) => this._setProfileState("liability", value)}
                  />
                  <Select
                    withoutInput={true}
                    tick={true}
                    label={internalization.form.sector}
                    isRequired={true}
                    hasError={(value) => {}}
                    errorLabel={internalization.form.empty}
                    defaultValue={this.props.profile.sector}
                    valueSelected={this.state.profile.sector}
                    options={internalization.dictionaries.sectors.map((value) => { return { value: value[0], text: value[1] }})}
                    optionClick={(text, value) => this._setProfileState("sector", value) }
                  />
                </form>
                <div className="c-button__wrapper">
                  <Button
                    className="o-margin--right-auto o-margin--left-auto"
                    name="login"
                    type="submit"
                    text={internalization.buttons.send}
                    disabled={this.state.submitting}
                    onClick={() => this._submit(this.state.profile)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <Modal isVisible={this.state.showModal}>
          <p>{this.state.modalData.title}</p>
          <div className="c-modal__buttons-wrapper">
            <button 
              type="button" 
              className="c-button c-modal__button"
              onClick={() =>
                {this.state.modalData.action()}
              }
            >
              <span
                className="c-button__text"
              >
                {internalization.buttons.accept}
              </span>
            </button>
          </div>
        </Modal>        

      </motion.section>
    );
  };

  static propTypes = {
    errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  };
}

const mapStateToProps = (state) => {
  return {
    profile: state.user.profile,
    userId: state.client.user_id,
  };
};

const mapDispatchToProps = {
  fetchShowProfile,
  fetchUpdateProfile,
  setUserLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
