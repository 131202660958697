import PropTypes from "prop-types";
import React from "react";
import { motion } from "framer-motion";

// Config
import routes from "../config/routes";

//Components
import Checkbox from "../components/Checkbox";
import Button from "../components/Button";
import LinkAnchor from "../components/LinkAnchor";
import TableData from "../components/TableData";

export default class Policy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
    };
  }
  handleCheckbox = (checkbox, state) => {
    this.setState({ isDisabled: !checkbox });
  };
  render() {
    return (
      <motion.section
        className="o-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="o-container">
          <div className="o-display--flex  o-display--flex-column-direction">
            <h5 className="o-margin o-display--flex-v-center o-margin--bottom-size-l">
              PRIVACY POLICY
            </h5>
            <div className="o-margin o-margin--bottom-size-xl">
              <p className="o-margin o-margin--bottom-size-l">
                Questa Applicazione raccoglie alcuni dati personali dei propri utenti
              </p>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
               Titolare del trattamento dei dati
              </h6>
              <p className="o-margin o-margin--bottom-size-l">
                I dati sono trattati per conto del titolare da Selexi s.r.l., con sede in via Gerolamo Vida, 11 – 20127 Milano (Italia). Email: servizi@selexi.it
                </p>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                Finalità del trattamento
              </h6>
              <p className="o-margin o-margin--bottom-size-l">
                I dati personali sono raccolti per la finalità di elaborare un report di personalità dell’interessato in contesto lavorativo. Il trattamento avviene sulla base legale di erogazione di un servizio richiesto dall’interessato stesso.
              </p>
              <p>
                I dati sono inseriti dall’interessato.
              </p>
              <p>
                Il rifiuto di fornire i dati richiesti comporta la mancata elaborazione di un report di personalità in contesto lavorativo dell’interessato.
              </p>
              <p>Per quanto riguarda il trattamento dei dati di navigazione (cookie), per i quali Selexi è titolare, vedere i dettagli successivi.</p>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                Tipologie di dati raccolti
              </h6>
              <p>
                I dati personali raccolti sono:
                <ul>
                <li>- dati di contatto, dati di registrazione e dati di navigazione raccolti dai cookie;</li>
                <li>- esperienze personali, competenze, capacità, preferenze e attitudini personali;</li>
                </ul>
              </p>

            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                Modalità e luogo del trattamento dei dati raccolti
              </h6>
              <p className="o-margin o-margin--bottom-size-l">
                I dati sono trattati dal responsabile adottando le opportune misure di sicurezza volte a impedirne l’accesso, la divulgazione, la modifica o la distruzione non autorizzate.
              </p>
              <p className="o-margin o-margin--bottom-size-l">I dati sono trattati anche da sub-fornitori di Selexi (p.e. fornitori di servizi tecnici terzi, hosting provider) con il ruolo di responsabili del trattamento e con contratto formale per assicurare la correttezza delle loro attività e assicurare la protezione dei dati personali e il rispetto di questa privacy policy.</p>
              <p className="o-margin o-margin--bottom-size-l">I dati non sono trasferiti in Paesi extra-UE.</p>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                Tempi di conservazione
              </h6>
              <p className="o-margin o-margin--bottom-size-l">
               I dati sono conservati da Selexi secondo quanto stabilito dal titolare.
              </p>
              <p className="o-margin o-margin--bottom-size-m">
                In mancanza di indicazioni da parte del titolare, Selexi conserva i dati come segue:<br/>
                fino al 31/12 dell’anno successivo alla ricezione dei dati stessi (per assicurare il diritto di difesa, eventuali necessità di rielaborazione dei dati e di rispondere a contenziosi), salvo una durata più ampia determinata dalla presenza di un contenzioso, per cui i dati sono conservati fino alla conclusione del contenzioso stesso.
              </p>
              
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                Cookies
              </h6>

              <p className="o-margin o-margin--bottom-size-l">
                Raccogliamo automaticamente alcuni tipi di dati ogni volta che interagite con noi sul nostro sito o via e-mail. Le tecnologie di raccolta automatica che utilizziamo comprendono, ad esempio, i registri di server Web/indirizzi IP, i cookie e i beacon Web.
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                Registri di server Web/Indirizzi IP L’indirizzo IP è un numero che viene assegnato al vostro computer ogni volta che accedete ad Internet. Tutti i computer collegati ad Internet vengono identificati mediante un indirizzo IP: ciò consente a computer e server di riconoscersi e di comunicare tra loro. Roche raccoglie gli indirizzi IP per amministrare il sistema e trasmette i dati aggregati ad affiliate, partner commerciali e/o fornitori per effettuare analisi del sito e valutazioni della performance del sito Web. Cookie
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                I cookie sono informazioni che vengono salvate automaticamente sul disco rigido del vostro computer quando accedete a determinati siti Web e che identificano il vostro browser in modo univoco. I cookie ci consentono di memorizzare sul server delle informazioni che ci aiutano a migliorare la vostra navigazione e a condurre analisi per valutare la performance del sito. La maggior parte dei browser Web accetta i cookie automaticamente: l’utente può tuttavia modificare le impostazioni del proprio browser in modo tale da rifiutare tutti i cookie o da essere avvisato quando ne viene inviato qualcuno. Alcune aree dei nostri siti potrebbero tuttavia non funzionare correttamente qualora rifiutiate i cookie. I cookie non raccolgono automaticamente i dati di identificazione personale che riguardano l’utente.
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                <i>Cookie di profilazione</i>
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                Mediante la funzionalità cookie, Selexi s.r.l. procederà altresì a tracciare le abitudini di navigazione dell’utente (e.g. sezioni consultate, accessi al sito, pagine viste… ) associandole ai dati personali di quest’ultimo forniti in sede di registrazione all’Area Riservata, in piena conformità con la normativa sulla protezione dei dati personali applicabile, con la finalità di ottimizzare e razionalizzare l’attività di comunicazione e informazione attuata da Selexi s.r.l..
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                <i>Beacon Web</i>
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                In certi casi, Selexi s.r.l. potrebbe avvalersi di una tecnologia comune basata sull’uso dei cosiddetti “beacon Web” (noti anche come “clear GIF” o “action tag” ) in talune pagine Web o in determinati messaggi di posta elettronica. I beacon Web consentono di analizzare l’efficacia dei siti Web misurando, per esempio, il numero di visitatori di un determinato sito o il numero di visitatori che cliccano su taluni elementi chiave del sito. I beacon web non raccolgono automaticamente i dati di identificazione personale che riguardano l’utente.
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                <i>Statistica</i>
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                Servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                Google Analytics (Google Inc.).<br/>
                Google Analytics è un servizio di analisi web fornito da Google Inc. (“Google”).<br/>
                Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questa Applicazione, compilare<br/>
                report e condividerli con gli altri servizi sviluppati da Google.<br/>
                Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network 
                pubblicitario.<br/>
                Dati Personali raccolti: Cookie e Dati di Utilizzo.<br/>
                Luogo del trattamento: USA  – <a href="https://www.google.com/intl/it/policies/privacy/">Privacy Policy – Opt Out</a>
              </p>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-l">
                Esercizio dei diritti da parte degli utenti
              </h6>

              <p className="o-margin o-margin--bottom-size-l">
                I soggetti cui si riferiscono i dati personali hanno il diritto in qualunque momento di ottenere la conferma dell’esistenza o meno degli stessi presso il titolare del trattamento, di conoscerne il contenuto e l’origine, di verificarne l’esattezza o chiederne la rettifica o la cancellazione, o il blocco dei dati personali, nonché di opporsi in ogni caso, per motivi legittimi, al loro trattamento. Le richieste vanno rivolte al titolare del trattamento. Questa applicazione non supporta le richieste “Do Not Track”. 
              </p>
              <p>
                Gli interessati hanno diritto di inviare reclamo al Titolare o all’autorità di controllo (in Italia è il Garante per la protezione dei dati personali).
              </p>
            </div>
          </div>
        </div>
      </motion.section>
    );
  }

  static propTypes = {
    // name: PropTypes.string.isRequired, // this is passed from the Rails view
  };
}
