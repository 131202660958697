export const detectLanguage = () => {
  let current_language = navigator.language || navigator.userLanguage
  return "it";
  switch (true) {
    case /^es/.test(current_language):
      return "es";
      break;
    case /^ca/.test(current_language):
      return "cat";
      break;
    case /^pt/.test(current_language):
      return "pt";
      break;        
    default:
      return "en";
      break;
  }
};

export default detectLanguage;
