import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";

// Components
import Aside from "../../components/Aside";
import Button from "../../components/Button";
import Pagination from "../../components/Pagination";
import FolderItem from "../../components/FolderItem";
import ModalInvitationFolder from "../../components/admin-modals/ModalInvitationFolder";
import ModalCreate from "../../components/admin-modals/ModalCreate";
import ModalInfoAction from "../../components/admin-modals/ModalInfoAction";
import ModalInfoStatus from "../../components/admin-modals/ModalInfoStatus";
import ModalSelectOrganization from "../../components/admin-modals/ModalSelectOrganization";

import Spinner from "../../components/Spinner";
import Input from "../../components/Input";
import IllustrationLineSeparator from "../../components/IllustrationLineSeparator";
import Roles from "../../config/roles";

// CustomHook
import { usePagination } from "../../hooks/usePagination";

// Actions
import {
  fetchOrganization,
  fetchOrganizations,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchFolderDestroy,
  fetchFolderIndex,
  fetchFolderCreate,
  fetchFolderUpdate,
  fetchFolderCreateUsersFolder,
  fetchFolderUpdateChart,
} from "../admin/actions";
import { fetchLogout } from "../login/actions";

// Selectors
import { getOrganization, getOrganizations } from "../organizations/selector";

const FolderHome = (props) => {
  const [folderIdSelected, setFolderIdSelected] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(null);
  const [modals, setModals] = useState("");
  const [folderData, setFolderData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [modalMsg, setModalMsg] = useState("");
  const [valueSearch, setValueSearch] = useState("");

  useEffect(() => {
    props.fetchFolderIndex(props.match.params.organizationId, 1, 5000, () =>{ setLoading(false)});
  }, []);
  useEffect(() => {
    
    props.organizations.length === 0 && props.fetchOrganizations();
    if(props.organization === undefined || ""+props.organization.data.id !== ""+props.match.params.organizationId){
      props.fetchOrganization(
      props.match.params.organizationId,
      1,
      5000,
      () => {setLoading(false)},
      true
    );
      props.folderIndex === undefined && props.fetchFolderIndex(props.match.params.organizationId, 1, 5000, () =>{ setLoading(false)});
    }
    
  }, [props.match.params.organizationId]);

  const folderFiltered = () => {
    return props.folderIndex.filter(
      (value) =>
        value.name.toLowerCase().indexOf(valueSearch.toLowerCase(), 0) !== -1
    );
  };

  const {
    goToPreviousPage,
    goToNextPage,
    goToFirstPage,
    goToLastPage,
    goToPage,
    paginationManage,
    itemsForPage,
    checkFirst,
    checkLast,
  } = usePagination(props.folderIndex ? folderFiltered() : [], 8);

  useEffect(() => {
    setCurrentPage(-1);
    setTimeout(() => setCurrentPage(paginationManage.currentPagination), 500);
  }, [paginationManage.currentPagination]);

  const selectFolder = (id) => {
    let selectId = undefined;
    if (folderIdSelected !== id) {
      selectId = id;
    }
    setFolderIdSelected(selectId);
  };

  const actionModals = (folder, type) => {
    if (type === "delete") {
      setModalMsg("Are you sure you want to delete the survey?");
    } else if (type === "createFolder") {
      setModalMsg("The survey has been created successfully!");
    } else if (type === "invite") {
      if (folder.survey !== "") {
        setModalMsg("The users has been added successfully!");
      } else {
        type = "success";
        setModalMsg(
          "You must have a assessment assigned to be able to invite users. You can assign a assessment by editing the folder"
        );
      }
    }
    setModals(type);
    setFolderData(folder);
  };

  const selectAction = (id) => {
    props.history.push(`/admin/organizations/${id}/surveys`);
  };

  const submitEdit = (folder) => {
    let organizationId = props.organization.data.id;
    let folderId = folderData.id;

    props.fetchFolderUpdate(
      organizationId,
      folderId,
      { folder: folder },
      () => {
        successActionRefresh(),
          setModals("success"),
          setModalMsg("The survey has been edited successfully!");
      }
    );
  };

  const submitCreate = (folder) => {
    let organizationId = props.organization.data.id;

    props.fetchFolderCreate(organizationId, { folder: folder }, () => {
      successActionRefresh("success");
    });
  };

  const submitDelete = (folder) => {
    let organizationId = props.organization.data.id;
    let folderId = folderData.id;
    setLoading(true);
    props.fetchFolderDestroy(organizationId, folderId, () =>
      successActionRefresh()
    );
  };

  const successActionRefresh = (type = "") => {
    setModals(type);
    props.fetchFolderIndex(props.match.params.organizationId, 1, 5000, () =>
      setLoading(false)
    );
  };

  const submitInvitation = (emails, file) => {
    let organizationId = props.organization.data.id;
    let folderId = folderData.id;

    if (emails.length > 0 || file !== undefined) {
      props.fetchFolderCreateUsersFolder(
        organizationId,
        folderId,
        { emails: emails, file: file },
        () => successActionRefresh("success")
      );
    }
  };

  const successAction = () => {
    setModals("success");
    props.fetchOrganization(props.match.params.organizationId, 1, 4);
  };

  const getAssessments = () => {
    props.fetchOrganizationGetAssessments(
      props.match.params.organizationId,
      () => setModals("createOrganization")
    );
  };

  const submitCreateOrganization = (data) => {
    props.fetchOrganizationCreate({ organization: data, from_organization: props.match.params.organizationId }, () => {
      setModals("success"),
        setModalMsg("The organization has been created successfully!"),
        props.fetchOrganizations();
    });
  };

  // Logout
  const closeSession = () => {
    removeAdminScopeClass();
    props.fetchLogout() && props.history.push("/signin");
  };

  // Remove s-admin Class
  const removeAdminScopeClass = () => {
    let sAdmin = document.querySelector(".s-admin");
    sAdmin && sAdmin.classList.remove("s-admin");
  };

  const actionModal = (type) => {
    if (type === "delete") {
      submitDelete();
    } else if (type === "closeSession") {
      closeSession();
    } else {
      setModals("");
    }
  };

  const getDataChart = (folderId, typeModal) => {
    props.fetchFolderUpdateChart(
      props.match.params.organizationId,
      folderId,
      "",
      (totals) => {
        setLoading(false), setModals(typeModal), setModalMsg(totals);
      },
      () => {}
    );
  };

  console.log(props.organizations_select)
  return (
    <motion.section
      className="o-wrapper o-wrapper--row"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {props.organizations &&
        props.organizations.length > 0 &&
        props.organization &&
        props.organization.data && (
          <Aside
            history={props.history}
            label={"Select organization:"}
            showSelect={true}
            organizations={props.organizations && props.organizations}
            organization={props.organization}
            selectAction={(id) => selectAction(id)}
            createOrganization={() => getAssessments()}
            selectOrganization={() => setModals("selectOrganization")}
            showModalSession={() => {
              setModals("closeSession"),
                setModalMsg("Are you sure you want to logout?");
            }}
          />
        )}

      {props.organization && props.organization.data && (
        <div className="o-container">
          <h1 key={"_name_id_"+props.organization.data.id} className="o-text-align o-text-align--left">
            {props.organization.data.name}
          </h1>

          {props.organization &&
            props.organization.user_role !== Roles.admin.READER && (
              <button
                key={"buttons-folder"}
                type="button"
                className={
                  "c-button c-button--margin-zero o-margin--top-size-l o-margin--bottom-size-xl"
                }
                onClick={() => actionModals({}, "createFolder")}
              >
                <span className="c-button__text">New assessment</span>
              </button>
            )}
          {props.folderIndex && props.folderIndex.length > 0 && (
            <div className="o-margin--top-size-l o-margin--bottom-size-l">
              <form className="o-width--max-width-500">
                <Input
                  className="c-input"
                  name="search"
                  label="Search surveys:"
                  search={true}
                  errorLabel="This field can not be blank"
                  minCharacters={5}
                  type="text"
                  value={valueSearch}
                  onChange={(e) => setValueSearch(e.currentTarget.value)}
                />
              </form>
            </div>
          )}

          {props.folderIndex && folderFiltered().length > 0 && (
            <div className="c-pagination__wrapper">
              <AnimatePresence exitBeforeEnter>
                {currentPage === paginationManage.currentPagination && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="o-grid o-grid--two-four o-margin--bottom-size-xl"
                  >
                    {folderFiltered()
                      .slice(
                        paginationManage.paginationStart,
                        paginationManage.paginationEnd
                      )
                      .map((folder, index) => {
                        let selected =
                          folderIdSelected === index ? "show" : "hidden";
                        return (
                          <FolderItem
                            key={folder.id}
                            index={index}
                            alignLeft={true}
                            name={folder.name}
                            canManage={
                              props.organization &&
                              props.organization.user_role !==
                                Roles.admin.READER
                            }
                            goTo={() =>
                              props.history.push(
                                `/admin/organizations/${props.organization.data.id}/surveys/${folder.id}`
                              )
                            }
                            selected={selected}
                            selectFolder={(index) => selectFolder(index)}
                            deleteFolder={() => actionModals(folder, "delete")}
                            editFolder={() => actionModals(folder, "edit")}
                            invitedFolder={() => actionModals(folder, "invite")}
                            infoStatusFolder={() => {
                              setLoading(true);
                              getDataChart(folder.id, "infoStatusByFolder");
                            }}
                          />
                        );
                      })}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )}
          {props.folderIndex && props.folderIndex.length === 0 && (
            <h2 className="o-text-align--center o-margin--top-size-xxl">
              Currently there are no folders
            </h2>
          )}

          {props.folderIndex && folderFiltered().length > itemsForPage && (
            <Pagination
              marginTop={true}
              paginationManage={paginationManage}
              pagination={folderFiltered()}
              goToPage={(index) => goToPage(index)}
              goToLastPage={() => goToLastPage()}
              goToFirstPage={() => goToFirstPage()}
              goToNextPage={() => goToNextPage()}
              goToPreviousPage={() => goToPreviousPage()}
              itemsForPage={itemsForPage}
              isFirst={() => checkFirst()}
              isLast={() => checkLast()}
            />
          )}
        </div>
      )}
      {modals === "invite" && (
        <ModalInvitationFolder
          showModalInvitationFolder={modals === "invite"}
          closeInvitationFolder={() => setModals("")}
          invitationAction={(data, file) => submitInvitation(data, file)}
        />
      )}

      {["createFolder", "createOrganization", "edit"].includes(modals) && (
        <ModalCreate
          showModal={true}
          closeModal={() => setModals("")}
          editAction={(data) =>
            modals === "edit"
              ? submitEdit(data)
              : modals === "createFolder"
              ? submitCreate(data)
              : submitCreateOrganization(data)
          }
          defaultData={folderData}
          type={modals}
          title={
            modals !== "edit"
              ? modals === "createFolder"
                ? "New assessment"
                : "Nuova organizzazione:"
              : "Edit survey"
          }
          assessments={
            ["createFolder", "edit"].includes(modals)
              ? props.organization.data.assessments
              : props.assessments
          }
          biodataOptions={props.organization.data.biodata_options}
        />
      )}

      {
        modals === "selectOrganization" && (
          <ModalSelectOrganization
            history={props.history}
            organizations={props.organizations_select}
            closeModal={() => setModals("")}
          />
        )
      }

      {modals === "infoStatusByFolder" && (
        <ModalInfoStatus
          showModal={modals === "infoStatusByFolder"}
          title={"Utenti totali:"}
          text={modalMsg}
          closeModal={() => {
            setModals(""), setModalMsg("");
          }}
        />
      )}

      {["delete", "success", "closeSession"].includes(modals) && (
        <ModalInfoAction
          showModalInfoAction={true}
          noButtonCancel={["success"].includes(modals)}
          closeModalInfoAction={() => setModals("")}
          text={modalMsg}
          action={() => actionModal(modals)}
        />
      )}
      {loading && <Spinner />}
    </motion.section>
  );
};

FolderHome.propTypes = {
  folder: PropTypes.object,
};

const mapStateToProps = (state, props) => {
  return {
    state,
    folderIndex: state.admin.folderIndex,
    organization: getOrganization(state, props),
    organizations: getOrganizations(state, props),
    assessments: state.admin.assessments,
    organizations_select: state.admin.organizations_select

  };
};

const mapDispatchToProps = {
  fetchOrganization,
  fetchOrganizations,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchFolderDestroy,
  fetchFolderIndex,
  fetchFolderCreate,
  fetchFolderUpdate,
  fetchFolderCreateUsersFolder,
  fetchFolderUpdateChart,
  fetchLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(FolderHome);
