import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import classNames from "classnames";

// Components
import Checkbox from "./Checkbox";
import Multiselect from "./Multiselect";
import ProgressBar from "./ProgressBar";
import Pagination from "./Pagination";
import PaginationNew from './PaginationNew'
import Eye from "./Eye";
import Edit from "./Edit";
import Envelope from "./Envelope";
import Close from "./Close";
import Chevron from "./Chevron";
import Notification from "./Notification";

// CustomHook
import { usePagination } from "../hooks/usePagination";
import { usePaginationNew}  from "../hooks/usePaginationNew";

const TableReportNew = (props) => {
  const [userList, setUserList] = useState([]);
  const [typeOrder, setTypeOrder] = useState("");
  const [modeOrder, setModeOrder] = useState("asc");

   const {
    goToPreviousPage,
    goToNextPage,
    goToFirstPage,
    goToLastPage,
    goToPage,
    paginationManage,
    itemsForPage,
    checkFirst,
    checkLast,
    reset,
  } = usePaginationNew(props.data ? props.data : [], 10, props.total_pages, props.current_page, (page) => {props.toPage(page, typeOrder, modeOrder) }) 

  useEffect(() => {
    setUserList(props.data);
  }, [props.data]);
  
  useEffect(() => {
    reset();
  }, [props.filterAction]);

  const selectUser = (user) => {
    let allIds = props.ids;
    if (allIds.length > 0) {
      if (
        allIds.find((item) =>
          props.type === "administrators"
            ? item.id === user.id
            : item.userFolderId === user.user_folder_id
        ) === undefined
      ) {
        allIds = [
          ...props.ids,
          {
            userFolderId: user.user_folder_id,
            id: user.id,
            status: user.status,
          },
        ];
      } else {
        allIds = props.ids.filter((item) =>
          props.type === "administrators"
            ? item.id !== user.id
            : item.userFolderId !== user.user_folder_id
        );
      }
    } else {
      allIds = [
        ...props.ids,
        { userFolderId: user.user_folder_id, id: user.id, status: user.status },
      ];
    }

    props.getIds(allIds, false);
  };

  const selectAllUsers = () => {
    let allIds = [];
    if (userList.length !== props.ids.length) {
      userList.map((item) =>
        allIds.push({
          id: item.id,
          userFolderId: item.user_folder_id,
          status: item.status,
        })
      );
    }
    props.getIds(allIds, userList.length !== props.ids.length);
  };

  const orderItems = (type, mode) => {
    reset();
    let _userList = JSON.parse(JSON.stringify(userList).toLowerCase());
    let orderList = [];

    orderList = _userList.sort((a, b) =>
      a[type] > b[type] ? 1 : b[type] > a[type] ? -1 : 0
    );

    if (mode === "desc") {
      orderList = _userList.reverse();
    }

    setTypeOrder(type);
    setModeOrder(mode);
    setUserList(orderList);
    props.toPage(1, type, mode);

  };

  return (
    <React.Fragment>
      <div
        className={
          props.type === "administrators"
            ? "c-table-report o-grid o-grid--four"
            : "c-table-report o-grid o-grid--five o-margin--bottom-size-giga"
        }
      >
        <div className="c-table-report__column">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
          >
            <Checkbox
              key={"check-email-title"}
              text="Email"
              textClassName="c-checkbox__text c-checkbox__text--bold"
              onClick={() => {
                selectAllUsers();
              }}
              checked={
                userList.length > 0 &&
                props.ids.length &&
                userList.length === props.ids.length
              }
            />
            <div className="c-table-report__cell-buttons-icons">
              <div
                className="c-table-report__cell-buttons-icons-item"
                onClick={() => orderItems("email", "asc")}
              >
                <Chevron
                  orientation={"up"}
                  color={
                    typeOrder === "email" && modeOrder === "asc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
              <div
                className="c-table-report__cell-buttons-icons-item"
                onClick={() => orderItems("email", "desc")}
              >
                <Chevron
                  orientation={"down"}
                  color={
                    typeOrder === "email" && modeOrder === "desc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
            </div>
          </motion.div>
          {userList
            .map((item, index) => {
              return (
                <motion.div
                  key={"table-report-email-" + item.email}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                >
                  <Checkbox
                    key={"check-email-" + index}
                    textClassName={
                      props.type === "administrators" ? 
                      "c-checkbox__text--report"
                      :
                      props.allIds.includes(item.user_folder_id) 
                        ? "c-checkbox__text--report"
                        : "c-checkbox__text--report c-checkbox__text--bold c-checkbox__text--blue"
                    }
                    text={item.email}
                    onClick={() => {
                      selectUser(item);
                    }}
                    checked={
                      props.ids.find((user) =>
                        props.type === "administrators"
                          ? user.id === item.id
                          : user.userFolderId === item.user_folder_id
                      )
                        ? true
                        : false
                    }
                  />
                </motion.div>
              );
            })}
        </div>
        <div className="c-table-report__column">
          <div className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center">
            <p className="c-table-report__cell-text c-table-report__cell-text--bold">
              Nome
            </p>
            <div className="c-table-report__cell-buttons-icons">
              <div
                className="c-table-report__cell-buttons-icons-item"
                onClick={() => orderItems("name", "asc")}
              >
                <Chevron
                  orientation={"up"}
                  color={
                    typeOrder === "name" && modeOrder === "asc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
              <div
                className="c-table-report__cell-buttons-icons-item"
                onClick={() => orderItems("name", "desc")}
              >
                <Chevron
                  orientation={"down"}
                  color={
                    typeOrder === "name" && modeOrder === "desc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
            </div>
          </div>

          {userList
            .map((item, index) => {
              return (
                <motion.div
                  key={"table-report-name-" + item.email}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                >
                  <p className="c-table-report__cell__item c-table-report__cell-text">{ item.name ? item.name.toLowerCase() : "-"}</p>
                </motion.div>
              );
            })}
            
        </div>
        <div className="c-table-report__column">
          <div className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center">
            <p className="c-table-report__cell-text c-table-report__cell-text--bold">
              Data
            </p>
            <div className="c-table-report__cell-buttons-icons">
              <div
                className="c-table-report__cell-buttons-icons-item"
                onClick={() => orderItems("date", "asc")}
              >
                <Chevron
                  orientation={"up"}
                  color={
                    typeOrder === "date" && modeOrder === "asc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
              <div
                className="c-table-report__cell-buttons-icons-item"
                onClick={() => orderItems("date", "desc")}
              >
                <Chevron
                  orientation={"down"}
                  color={
                    typeOrder === "date" && modeOrder === "desc"
                      ? "#121A22"
                      : "#b2bec3"
                  }
                  width={"15px"}
                  height={"6px"}
                />
              </div>
            </div>
          </div>

          {userList
            .map((item, index) => {
              return (
                <motion.div
                  key={"table-report-date-" + item.email}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                >
                  <p className="c-table-report__cell__item">{item.date}</p>
                  <p className="c-table-report__cell-text c-table-report__cell-text--bold">
                    {item.status_name}
                  </p>
                </motion.div>
              );
            })}
            
        </div>
        {props.type !== "administrators" && (
          <div className="c-table-report__column">
            <div className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center">
              <p className="c-table-report__cell-text c-table-report__cell-text--bold">
                Status
              </p>
              <div className="c-table-report__cell-buttons-icons">
                <div
                  className="c-table-report__cell-buttons-icons-item"
                  onClick={() => orderItems("status", "asc")}
                >
                  <Chevron
                    orientation={"up"}
                    color={
                      typeOrder === "status" && modeOrder === "asc"
                        ? "#121A22"
                        : "#b2bec3"
                    }
                    width={"15px"}
                    height={"6px"}
                  />
                </div>
                <div
                  className="c-table-report__cell-buttons-icons-item"
                  onClick={() => orderItems("status", "desc")}
                >
                  <Chevron
                    orientation={"down"}
                    color={
                      typeOrder === "status" && modeOrder === "desc"
                        ? "#121A22"
                        : "#b2bec3"
                    }
                    width={"15px"}
                    height={"6px"}
                  />
                </div>
              </div>
            </div>
            {userList
              .map((item, index) => {
                if (
                  (item.progress === "100.0" || props.organization_id == 60) &&
                  item.report_select &&
                  item.report_select.length > 0
                ) {
                  return (
                    <motion.div
                      key={"table-report-progress-" + item.email}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                    >
                      <Multiselect
                        defaultValue="Informe"
                        multiselectClassName="c-multiselect--white-bg c-multiselect--border"
                        multiselectWrapperClassName='o-display--flex o-display--flex-v-center o-margin--bottom-zero'
                        multiple={false}
                        options={item.report_select}
                        getOption={(value) => {
                          props.showReport(value);
                        }}
                      />
                    </motion.div>
                  );
                } else {
                  return (
                    <motion.div
                      key={"table-report-progress-" + item.email}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center "
                    >
                      <ProgressBar
                        noTitle={true}
                        progress={
                          item.progress === "" ? 0 : parseInt(item.progress)
                        }
                        contentClasses='o-margin--bottom-zero'
                        admin={true}
                      />
                    </motion.div>
                  );
                }
              })}
          </div>
        )}
        {
          props.type !== "ranked_users" &&
          <div className="c-table-report__column">
            <div className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center ">
              <p className="c-table-report__cell-text c-table-report__cell-text--bold">
                Azioni
              </p>
            </div>

            {userList
              .map((item, index) => {
                return (
                  <motion.div
                    key={"table-report-date-" + item.email}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                  >
                    <div className="c-table-report__cell-icons">
                      <div
                        className="c-table-report__cell-icons-item"
                        onClick={() =>
                          props.ids.length === 0 ||
                          (props.ids.length === 1 &&
                            props.ids[0].userFolderId === item.user_folder_id)
                            ? props.openShowUserModal(item)
                            : {}
                        }
                      >
                        <Eye />
                      </div>
                      {props.canManage && (
                        <div
                          className="c-table-report__cell-icons-item"
                          onClick={() =>
                            props.ids.length === 0 ||
                            (props.ids.length === 1 &&
                              props.ids[0].userFolderId === item.user_folder_id)
                              ? props.openEditUserModal(item)
                              : {}
                          }
                        >
                          <Edit width={"18px"} height={"28px"} />
                        </div>
                      )}
                      <div
                        className="c-table-report__cell-icons-item"
                        onClick={() =>
                          props.ids.length === 0 ||
                          (props.ids.length === 1 &&
                            props.ids[0].userFolderId === item.user_folder_id)
                            ? props.openInvitedModal(item)
                            : {}
                        }
                      >
                        <Envelope />
                      </div>
                      {props.canManage && (
                        <div
                          className="c-table-report__cell-icons-item"
                          onClick={() => {
                            props.ids.length === 0 ||
                            (props.ids.length === 1 &&
                              props.ids[0].userFolderId === item.user_folder_id)
                              ? props.openDeleteModal(item)
                              : {};
                          }}
                        >
                          <Close color="#fc5c65" />
                        </div>
                      )}
                      {props.canManage && (
                        <div
                          className="c-table-report__cell-icons-item"
                          onClick={() => {
                            props.ids.length === 0 ||
                            (props.ids.length === 1 &&
                              props.ids[0].userFolderId === item.user_folder_id)
                              ? props.openNotificationModal(item)
                              : {};
                          }}
                        >
                          <Notification color={item.has_notifications ? 'black' : 'white'}/>
                        </div>
                      )}
                    </div>
                  </motion.div>
                );
              })}
          </div>          
        }
        {
          props.type === "ranked_users" &&
          <div className="c-table-report__column">
            <div className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center ">
              <p className="c-table-report__cell-text c-table-report__cell-text--bold">
                Puntuaction
              </p>
            </div>

            {userList
              .map((item, index) => {
                return (
                  <motion.div
                  key={"table-report-date-" + item.email}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                >
                  <p className="c-table-report__cell__item">{item.ranking}</p>
                </motion.div>
                );
              })}
          </div>          
        }

      </div>
      
        <PaginationNew
          paginationManage={paginationManage}
          total_pages={props.total_pages}
          current_page={props.current_page}
          pagination={userList}
          goToPage={(index) => goToPage(index)}
          goToLastPage={() => goToLastPage()}
          goToFirstPage={() => goToFirstPage()}
          goToNextPage={() => goToNextPage()}
          goToPreviousPage={() => goToPreviousPage()}
          itemsForPage={itemsForPage}
          isFirst={() => checkFirst()}
          isLast={() => checkLast()}
        />
   
      
    </React.Fragment>
  );
};

export default TableReportNew;
