import React from "react";
import Checkbox from "../../../../components/Checkbox";

const SurveyCheckboxItem = (props) => {
  const itemIndex = props.index;
  const itemData = props.questionData || {};

  const _handleCheckbox = (index, data) => {
    return props.handleClick({ [index]: data });
  };

  const _isCorrect = () => {
    if(props.example !== undefined){
      if(Object.keys(props.selectedItems).includes(itemIndex.toString())){
        return props.selectedItems[itemIndex.toString()] && props.selectedItems[itemIndex.toString()].correct ? true : false  
      }
      return undefined
    }
    else{
      return undefined
    }    
  }

  const _isSelected = () => {
    if(props.item_format_style === 11){
      return Object.keys(props.checkboxAnswers).includes(itemIndex.toString())
    }else{
      return Object.keys(props.selectedItems).includes(itemIndex.toString())  
    }
    
  }
  return (
    <Checkbox
      key={`${itemData.value}-${itemIndex}-${props.identifier}`}
      identifier={props.identifier}
      text={itemData.name || itemData.text}
      value={itemData.value}
      onClick={() => _handleCheckbox(itemIndex, itemData)}
      checked={_isSelected()}
      className="o-display--flex o-margin--bottom-size-l"
      groupClass="c-checkbox__tick-illustration-color--primary"
      correct={_isCorrect()}
      item_format_style={props.item_format_style}
    />
  );
};

export default SurveyCheckboxItem;
