import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import LinesEllipsis from "react-lines-ellipsis";

import routes from "../../../packs/config/routes";

// Components
import Aside from "../../components/Aside";
import ItemImageList from "../../components/ItemImageList";
import ModalInfoAction from "../../components/admin-modals/ModalInfoAction";
import ModalCreate from "../../components/admin-modals/ModalCreate";
import IllustrationLineSeparator from "../../components/IllustrationLineSeparator";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
import Roles from "../../config/roles";
import Multiselect from "../../components/Multiselect";
import Filter from "../../components/Filter";
import Close from "../../components/Close";

// CustomHook
import { usePagination } from "../../hooks/usePagination";

// Actions
import { fetchLogout } from "../login/actions";

// Selectors
import { getOrganization, getOrganizations } from "../organizations/selector";

// Actions
import {
  fetchOrganization,
  fetchOrganizations,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchLogsFromOrganization
} from "../admin/actions";


const LogsHome = (props) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modals, setModals] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [filterTags, setFilterTags] = useState([]);

  const refItemTags = useRef([]);

  const {
    goToPreviousPage,
    goToNextPage,
    goToFirstPage,
    goToLastPage,
    goToPage,
    paginationManage,
    itemsForPage,
    checkFirst,
    checkLast,
    reset,
  } = usePagination(logs ? logs : [], 10);

  useEffect(() => {
    props.fetchOrganizations();
    props.fetchOrganization(props.match.params.organizationId, 1, 5000);
    getLogsFromOrganization();
  }, [props.match.params.organizationId]);

  useEffect(() => {
    props.organizations.length === 0 && props.fetchOrganizations();

    props.organization === undefined &&
      props.fetchOrganization(props.match.params.organizationId, 1, 5000);

    getLogsFromOrganization();

    if (props.location.state && props.location.state.folderId) {
      changeFolder(parseInt(props.location.state.folderId));
    }
  }, []);

  const getLogsFromOrganization = () => {
    console.log("getLogsFromOrganization")
    props.fetchLogsFromOrganization(
      props.match.params.organizationId,
      1,
      5000,
      (data) => {
        console.log(data)
        setLogs(data);
        setLoading(false);
        setFromFolder({});
      }
    );
  };

  const changeOrganization = (id) => {
    props.history.push(`/admin/organizations/${id}/logs`);
  };

  const actionModals = (type) => {
    setModals(type);
  };

  const getAssessments = () => {
    props.fetchOrganizationGetAssessments(
      props.match.params.organizationId,
      () => actionModals("createOrganization")
    );
  };

  const submitCreateOrganization = (data) => {
    props.fetchOrganizationCreate({ organization: data, from_organization: props.match.params.organizationId }, () => {
      setModals("success"),
        setModalMsg("The organization has been created successfully"),
        props.fetchOrganizations();
    });
  };

  // Logout
  const closeSession = () => {
    removeAdminScopeClass();
    props.fetchLogout() && props.history.push("/signin");
  };

  // Remove s-admin Class
  const removeAdminScopeClass = () => {
    let sAdmin = document.querySelector(".s-admin");
    sAdmin && sAdmin.classList.remove("s-admin");
  };

  const actionModal = (type) => {
    if (type === "delete") {
      deleteEmail();
    } else if (type === "closeSession") {
      closeSession();
    } else {
      setModals("");
    }
  };

  //Filter

  const logsOptionsFilter = () => {
    let options = [];

    options =
      logs && logs
        .filter((log, index, self) => {
          if (log.name && log.name !== "") {
            return index === self.findIndex((t) => (
              t.name === log.name
            ))
          }
        })
        .map((option, index) => {
          return { value: index, text: option.name };
        })

    return options;
  };

  const selectedFilterTags = (options) => {

    let tags = options.map((item) => logsOptionsFilter()[item].text);

    reset();
    setFilterTags(tags);
  };

  const deleteFilterTag = (item) => {
    let tags = filterTags.slice(0);
    let tag_index = tags.indexOf(item);
    if(tag_index !== -1) {
      tags.splice(tag_index, 1);
    };

    refItemTags.current.map((ref) => {
      if (ref.innerText === item) {
        ref.click();
      }
    });

    reset();
    setFilterTags(tags);
  };

  const filteredLogs = () => {
    let filteredLogs = [];
    if(filterTags.length > 0) {
      filterTags.map((tag, index) => {
        if(logs.length > 0) {
          filteredLogs = [...filteredLogs, logs.filter(log => log.name === tag)] 
        };
      });
    } else {
      filteredLogs = logs;
    };

    return filteredLogs.flat();
  };

  return (
    <motion.section
      className="o-wrapper o-wrapper--row"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {props.organizations.length > 0 &&
        props.organization &&
        props.organization.data && (
          <Aside
            history={props.history}
            label={"Select organization:"}
            showSelect={true}
            organizations={props.organizations}
            organization={props.organization}
            selectAction={(id) => changeOrganization(id)}
            createOrganization={() => getAssessments()}
            showModalSession={() => {
              setModals("closeSession"),
                setModalMsg("Are you sure you want to logout?");
            }}
          />
        )}

      <div className="o-container">
        <div className="c-block-title o-margin--bottom-size-l">
          <div className="c-block-title__left">
            <h1 className="c-block-title__big-text o-margin--bottom-size-xl">
              Logs
            </h1>
            <div className="c-block-title__left-content">
              <div className="c-block-title__left-filter">
                {logs.length > 0 && (
                  <div className="c-block-title__item">
                    <p className="c-block-title__small-text">{"Filter by name:"}</p>
                    <Multiselect
                      refItem={refItemTags}
                      multiselectClassName="c-multiselect--white-bg c-multiselect--border"
                      multiple={true}
                      options={logsOptionsFilter()}
                      getOption={(option) => selectedFilterTags(option)}
                    />
                  </div>
                )}
              </div>
            </div>
            {filterTags.length > 0 && (
              <div className="c-filter-box o-margin--bottom-size-l">
                <div className="c-filter-box__wrapper c-filter-box__wrapper--min-width-small c-filter-box__wrapper--align-start">
                  <div className="c-filter-box__wrapper c-filter-box__wrapper--align-center">
                    <Filter width="40px" height="40px" />
                    <p className="c-filter-box__text">
                      Selected filtri:
                    </p>
                  </div>
                </div>
                <div className="c-filter-box__wrapper c-filter-box__wrapper--flex-wrap">
                  {filterTags.map((item, index) => {
                    return (
                      <div
                        className="c-filter-box__box"
                        onClick={() => deleteFilterTag(item)}
                      >
                        <p className="c-filter-box__box-text">
                          {item}
                        </p>
                        <Close width="10px" height="10px" />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
        {filteredLogs()
          .slice(
            paginationManage.paginationStart,
            paginationManage.paginationEnd
          )
          .map((item, index) => {
            return (
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="c-test"
                >
                  <div className="c-test__content c-test__content--align-center">
                    <div className="c-test__data">
                      <h4 className="o-color--primary o-margin--bottom-size-s">
                        {item.name}
                      </h4>
                      <div className="o-margin--bottom-size-s">
                        <p>{item.resource_info} - [{item.resource_detail}]</p>
                      </div>
                      <p>Created: {item.created_at} by {item.user_name}</p>
                    </div>
                  </div>
                </motion.div>
              </AnimatePresence>
            );
          })}
        {filteredLogs().length > itemsForPage && (
          <Pagination
            marginTop={true}
            paginationManage={paginationManage}
            pagination={filteredLogs()}
            goToPage={(index) => goToPage(index)}
            goToLastPage={() => goToLastPage()}
            goToFirstPage={() => goToFirstPage()}
            goToNextPage={() => goToNextPage()}
            goToPreviousPage={() => goToPreviousPage()}
            itemsForPage={itemsForPage}
            isFirst={() => checkFirst()}
            isLast={() => checkLast()}
          />
        )}
      </div>
      {["delete", "closeSession"].includes(modals) && (
        <ModalInfoAction
          showModalInfoAction={true}
          noButtonCancel={["success"].includes(modals)}
          closeModalInfoAction={() => setModals("")}
          text={modalMsg}
          action={() => actionModal(modals)}
        />
      )}
      {loading && <Spinner />}
    </motion.section>
  );
};

const mapStateToProps = (state, props) => {
  return {
    organizations: getOrganizations(state, props),
    organization: getOrganization(state, props),
    logs: state.admin.logsOrganization
  };
};

const mapDispatchToProps = {
  fetchLogout,
  fetchOrganization,
  fetchOrganizations,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchLogsFromOrganization
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsHome);
