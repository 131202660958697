import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

// Components
import Chevron from "./Chevron";
import ModalCloseSession from "./ModalCloseSession";
import Select from "./Select";
import Close from "./Close";
import IllustrationFolder from "./IllustrationFolder";
import IllustrationHead from "./IllustrationHead";
import Envelope from "./Envelope";
import ArrowDouble from "./ArrowDouble";
import Hamburger from "./Hamburger";
import Lock from "./Lock";
import Dashboard from "./Dashboard";
import Clock from "./Clock";
import IllustrationProcedure from "./IllustrationProcedure";
import Roles from "../config/roles";

// Config
import { fetchLogout } from "../containers/login/actions";

const SideBar = (props) => {
  const menu = [
    {
      option: "Processi",
      descendants: [],
      icon: <IllustrationProcedure width={"30px"} height={"26px"} style={{marginLeft: 4}} />,
      tooltipText: "Processi",
      action: () =>
        props.history.push(
          `/admin/organizations/${props.organizationId}/procedures`
        ),
    },  
    {
      option: "Assessment",
      descendants: [],
      icon: <IllustrationFolder width={"30px"} height={"36px"} firstColor={"#A9C44B"} secondaryColor={"#fff"} style={{marginLeft: 4}}/>,
      tooltipText: "Assessment",
      action: () =>
        props.history.push(
          `/admin/organizations/${props.organizationId}/surveys`
        ),
    },
    // {
    //   option: "Emails",
    //   descendants: [],
    //   icon: <Envelope width={"26px"} height={"24px"} />,
    //   tooltipText: "Emails",
    //   action: () =>
    //     props.history.push(
    //       `/admin/organizations/${props.organizationId}/emails`
    //     ),
    // },
    {
      option: "Amministratori",
      descendants: [],
      icon: <Lock width={"32px"} height={"32px"} />,
      tooltipText: "Amministratori",
      action: () =>
        props.history.push(
          `/admin/organizations/${props.organizationId}/administrators`
        ),
    },
    // {
    //   option: "Logs",
    //   descendants: [],
    //   icon: <Clock width={28} height={"36px"} />,
    //   tooltipText: "Logs",
    //   action: () =>
    //     props.history.push(
    //       `/admin/organizations/${props.organizationId}/logs`
    //     ),
    // },
    {
      option: "Logout",
      descendants: [],
      tooltipText: "Logout",
      icon: (
        <Close
          width={"20px"}
          height={"22px"}
          rotation
          color={"#fc5c65"}
        />
      ),
      action: () => props.showModalSession(),
    },
  ];

  // useState
  const [showModal, setShowModal] = useState(false);
  const [currentScreen, setCurrentScreen] = useState("");

  useEffect(() => {
    let pathname = props.history.location.pathname;
    let uri = pathname.split("/");

    uri.forEach((word, index) => {
      if (word === "procedures") {
        setCurrentScreen(0);
      } else if (word === "surveys") {
        setCurrentScreen(1);        
      } else if (word === "administrators") {
        setCurrentScreen(2);
      } else if (word === "logs") {
        setCurrentScreen(3)
      }
    });
    if (pathname === `/admin/organizations/${props.organizationId}`) {
      setCurrentScreen(-1);
    }
  }, [props.history.location.pathname, props.organizationId]);

  const actionMenu = (e, item, descendants) => {
    e.preventDefault();
    e.stopPropagation();

    if (descendants.length > 0) {
      e.currentTarget.classList.toggle("is-visible");
      let chevron = e.currentTarget.querySelector(".c-chevron");
      chevron.classList.toggle("c-chevron--up");
    } else {
      // closeSidebar();
      item.action();
    }
  };

  // Handle items classes
  const menuItemsClasses = (menuLength, index, option) => {
    let menuItemsClass = classNames(
      "c-sidebar__nav-item",
      index === currentScreen && "is-active",
      menuLength - 1 === index && "o-color--red",
      index === 3 && "o-margin--bottom",
      ["Folders", "Emails", "Administrators", "Logs", "Logout"].includes(option) &&
        "c-sidebar__nav-item--no-padding-top-bottom"
    );
    return menuItemsClass;
  };

  const sidebarClasses = () => {
    let adminEl = document.querySelector(".s-admin");
    let sidebarClasses = classNames(
      "c-sidebar",
      adminEl && adminEl.classList.contains("has-sidebar") && "is-open"
    );
    return sidebarClasses;
  };

  const hamburgerClasses = () => {
    let adminEl = document.querySelector(".s-admin");
    let hamburgerClasses = classNames(
      "c-hamburger",
      adminEl && adminEl.classList.contains("has-sidebar") && "is-change"
    );
    return hamburgerClasses;
  };

  const openSidebar = () => {
    let adminEl = document.querySelector(".s-admin");
    let hamburger = document.querySelector(".c-hamburger");
    let aside = document.querySelector(".c-sidebar");
    adminEl.classList.toggle("has-sidebar");
    hamburger.classList.toggle("is-change");
    aside.classList.toggle("is-open");
  };

  const closeSidebar = () => {
    let adminEl = document.querySelector(".s-admin");
    let hamburger = document.querySelector(".c-hamburger");
    let aside = document.querySelector(".c-sidebar");
    adminEl.classList.remove("has-sidebar");
    hamburger.classList.remove("is-change");
    aside.classList.remove("is-open");
  };

  const organizationsData = () => {
    return props.organizations.map((org, index) => {
      return { value: org.id, text: org.name };
    });
  };

  const changeOrganizationAction = () => {
    let adminEl = document.querySelector(".s-admin");
    if (adminEl && !adminEl.classList.contains("has-sidebar")) {
      openSidebar();
    }
  };

  return (
    <>
      <nav className={sidebarClasses()}>
        <ul className="c-sidebar__nav">
          <li className="c-sidebar__nav-item o-margin--bottom-size-s">
            <div className="c-sidebar__nav-item-icon">
              <div className="c-menu-hamburger" onClick={() => openSidebar()}>
                <Hamburger width={"26px"} classNames={hamburgerClasses()} />
              </div>
            </div>
          </li>
          <li
            className={`${menuItemsClasses(menu.length, -1)}`}
            onClick={() =>
              props.history.push(`/admin/organizations/${props.organizationId}`)
            }
          >
            <div className="c-sidebar__nav-item-icon">
              <div className="c-sidebar__nav-item-icon-tooltip">
                <span className="c-sidebar__nav-item-icon-tooltip-text">
                  Organizzazione
                </span>
              </div>
              <Dashboard width={31} height={24}/>
            </div>

            <div className="c-sidebar__nav-item-option">
              <p>Organizzazione</p>
            </div>
          </li>
          <li
            className="c-sidebar__nav-item c-sidebar__nav-item--height-auto"
            onClick={() => props.selectOrganization()}
          >
            <div className="c-sidebar__nav-item-icon">
              <div className="c-sidebar__nav-item-icon-tooltip">
                <span className="c-sidebar__nav-item-icon-tooltip-text">
                  Cambiare organizzazione
                </span>
              </div>
              <ArrowDouble width={22} height={28} />
            </div>

            <div className="c-sidebar__nav-item-option">
              {props.showSelect && (
                <div className="c-sidebar__nav-item-option-content">
                  <p className="o-font-p--small">{props.label}</p>
                  <Select
                    className="c-select--white-bg c-select--border"
                    errorLabel=""
                    withoutInput={true}
                    tick={false}
                    valueSelected={`${props.organization.data.id}`}
                    options={organizationsData()}
                    optionClick={(text, value) => {
                      props.selectAction(value);
                      closeSidebar();
                    }}
                    wrapperClassName={"c-select__wrapper--height-zero"}
                  />
                </div>
              )}
            </div>
          </li>
          {props.organization && props.organization.user_role !== Roles.admin.READER &&
            <li
              className="c-sidebar__nav-item"
              onClick={() => props.createOrganization()}
            >
              <div className="c-sidebar__nav-item-icon">
                <div className="c-sidebar__nav-item-icon-tooltip">
                  <span className="c-sidebar__nav-item-icon-tooltip-text">
                    Nuova organizzazione
                  </span>
                </div>
                <Close width={22} height={22} style={{marginRight: 2}} />
                </div>

                <div className="c-sidebar__nav-item-option">
                  <p>Nuova organizzazione</p>
                </div>
              </li>
            }

            {menu.map((item, index) => {
              return (
                <li
                  key={"sidebar-menu-" + index}
                  className={menuItemsClasses(menu.length, index, item.option)}
                  onClick={(e) => {
                    actionMenu(e, item, item.descendants);
                  }}
              >
                {item.descendants.length > 0 ? (
                  <div className="c-sidebar__nav-list-item-icon">
                    <p className="c-sidebar__nav-list-item-text">
                      {item.option}
                    </p>
                    <Chevron
                      orientation=""
                      className={"c-chevron c-chevron--small"}
                      color={"#fff"}
                    />
                  </div>
                ) : (
                  <>
                    <div className="c-sidebar__nav-item-icon">
                      <div className="c-sidebar__nav-item-icon-tooltip">
                        <span className="c-sidebar__nav-item-icon-tooltip-text">
                          {item.tooltipText}
                        </span>
                      </div>
                      {item.icon}
                    </div>

                    <div className="c-sidebar__nav-item-option">
                      <p>{item.option}</p>
                    </div>
                  </>
                )}

                {item.descendants.length > 0 && (
                  <ul className="c-sidebar__nav-list-item__submenu">
                    {item.descendants.length > 0 &&
                      item.descendants.map((descendants, index2) => {
                        return (
                          <li
                            key={"sidebar-submenu-" + index2}
                            className="c-sidebar__nav-list-item__submenu-item"
                            onClick={(e) => {
                              actionMenu(
                                e,
                                descendants,
                                descendants.descendants
                              );
                            }}
                          >
                            <span className="c-sidebar__nav-list-item__submenu-item-dot"></span>
                            <p>{descendants.option}</p>
                          </li>
                        );
                      })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
};

const mapDispatchToProps = {
  fetchLogout,
};

export default connect(null, mapDispatchToProps)(SideBar);
